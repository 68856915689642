import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-returnedproducts',
  templateUrl: './returnedproducts.component.html',
  styleUrls: ['./returnedproducts.component.css']
})
export class ReturnedproductsComponent implements OnInit {
  fileName = 'sale_return_product_list.xlsx';

  searchval: any;
  pageLoadingImg: boolean;
  salesSum: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  repDate: any;
  period_type: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val1: any;
  inv_val2: any;
  vat_filter: any;
  vat_val1: any;
  vat_val2: any;
  gd_id: any;
  mfs: any; prd_manufact_id: any;

  // purch_qty_filter:any;
  // purch_qty:any;
  // purch_qty2:any;
  // barcode_filter:any;
  // bar_val1:any;
  // bar_val2:any;
  prd_id: any;
  cat_id: any;
  added_by: any;
  products: any; catagories: any;
  users: any;


  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  summary: any;
  godowns: any;
  report_type_filter = [
    { id: 'summary', name: 'Summary' },
    { id: 'detailed', name: 'Detailed' }
  ];
  // rept_type = [
  //   { id: 1, name: 'Sold & Returns' },
  //   { id: 2, name: 'Sold' },
  //   { id: 3, name: 'Returns' },
  // ];
  // rep_type: number;
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  subcatagories: any;
  up: any;
  customer: any;
  rep_type: any;
  report_type: any;
  exportLoader: boolean;
  ExpsalesSum: any[];
  Expsummary: any;
  Expreport_type: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  vans: any;
  country_dec: number=2;
  cur_lang: string;
  SaleRetProdList: any;
  heads: any[];
  langFilter: any;
  prd: any;
  
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.rep_type = 'summary';
  
    this.up = this.coreService.getUserPrivilage();
    // this.rep_type = 2;
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    this.pageLoadingImg = true;
    let searchval = new FormData();
    // searchval.append('rep_type','2');
    this.salesSum = [];

    this.apiService.returnedProductReport(searchval, 1).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      this.salesSum = res['data']['data'];
      this.summary = res['data'];

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.searchUser('');
    this.getGodownList();

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    
    
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.detailed','Common.summary']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.report_type_filter = [
        { id: 'summary', name: res['Common.summary'] },
        { id: 'detailed', name: res['Common.detailed']  }
      ];
    });

  }
  ngAfterContentInit(){   
    this.searchVans('');
  }
  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res: any) => {
      this.vans = res.data;
      this.vans.push({ van_id: 'All', van_name: 'All' });
    });

  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }

    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }


  ngOnDestroy() {
    $('.close').click();

  }
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }


  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';

    this.salesSum = [];

    this.apiService.returnedProductReport(formdata.value, pageno).subscribe((res: Sales) => {
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.salesSum = res['data']['data'];
      if (formdata.value.rep_type == '1') {
        this.apiService.getsaleReturnTotal(formdata.value).subscribe((tot: Sales) => { // to get summary of report
          console.log('sale and return');
          this.summary = tot['data'];
        });
      } else {
        this.summary = res['data'];

      }
      this.report_type = res.data.rep_type;

      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });

  }


  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
      this.subcatagories = res['data'];
      this.subcatagories.push({ "subcat_id": 57, 'subcat_name': 'NONE' });
    });
  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }

  exportEXCL(formdata: { value: any; }) {

    this.exportLoader = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = 'export';

    this.ExpsalesSum = [];

    this.apiService.returnedProductReport(formdata.value, 1).subscribe((res: Sales) => {
      this.repDate = res.data.Date;
      this.ExpsalesSum = res['data']['data'];
      this.Expsummary = res['data'];
      this.Expreport_type = res.data.rep_type;

      setTimeout(() => {
        this.exportexcel();
      }, 3000);
    });



    



}

// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;

  formdata.value.export = 'export';

  this.ExpsalesSum = [];

  this.apiService.returnedProductReport(formdata.value, 1).subscribe((res: Sales) => {
    this.repDate = res.data.Date;
    this.ExpsalesSum = res['data']['data'];
    this.Expsummary = res['data'];
    this.Expreport_type = res.data.rep_type;
    this.SaleRetProdList = res.data;

    console.log(this.SaleRetProdList);
    
    if(this.Expreport_type == 'detailed'){
      this.heads = [

        this.translate.instant('Van.Return_Date'),
        this.translate.instant('Sales.return_ref_no'),
        this.translate.instant('Sales.Name_Of_The_Customer'),
        this.translate.instant('Common.Vat_Reg_No'),
        this.translate.instant('Purchase.Return_Amount'),
        this.translate.instant('Purchase.vat'),
        this.translate.instant('Table.item_total'),
        this.translate.instant('Table.item_name'),
        this.translate.instant('Production.return_qty')
      ];

    }
    else{

      this.heads = [
        this.translate.instant('Common.prd_name'),
        this.translate.instant('Common.category'),
        this.translate.instant('Common.sub_category'),
        this.translate.instant('Sales.return_ref_no'),
        this.translate.instant('Sales.sales_inv_no'),
        this.translate.instant('Van.Return_Date'),
        this.translate.instant('Table.Return_Rate'),
        this.translate.instant('Production.return_qty'),
        this.translate.instant('Purchase.Return_Amount')
      ];

    }
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];

        this.SaleRetProdList.data.forEach(item=>{

            if(this.Expreport_type == 'detailed'){
              
                if (this.translate.currentLang === 'Arabic') {
                    this.langFilter = item.ledger_alias || item.ledger_name_ar || item.ledger_name 
                    this.prd= item.prd_alias;
                } else if (this.translate.currentLang === 'English') {
                    this.langFilter = item.prd_name 
                    this.prd= item.prd_name;
                }
            
            data.push([
              this.datePipe.transform(item.salesub_date, 'dd/MM/yyyy'),
              item.salesretsub_salesret_no,
              this.langFilter,
              item.salesret_cust_tin,
              (item.totalamount).toFixed(this.country_dec),
              (item.salesub_tax).toFixed(this.country_dec),
              (item.totalamount + item.salesub_tax).toFixed(this.country_dec),
              this.prd,
              item.salesub_qty
            ])

          } else {

            item.items.forEach(item1=>{
              if (this.translate.currentLang === 'Arabic') {
                // this.langFilter = item.ledger_alias || item.ledger_name_ar || item.ledger_name 
                this.prd= item1.prd_alias;
            } else if (this.translate.currentLang === 'English') {
                // this.langFilter = item.prd_name 
                this.prd= item.prd_name;
            }
              data.push([
                this.prd + '\n' + item1.prd_barcode,
                item1.cat_name,
                item1.subcat_name,
                item1.salesretsub_salesret_no,
                (item1.inv_no == 0) ? ' ' : item1.inv_no,
                this.datePipe.transform(item1.salesub_date, 'dd/MM/yyyy'),
                (item1.sale_avg_amount).toFixed(this.country_dec),
                item1.salesub_qty,
                (item1.totalamount).toFixed(this.country_dec)
              ])
            });

          }
        });
  
       let doc = this.excelService.getPdfObj();
  
       var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Sales.sales_returned_product_list')}`,  valueXPos, headerHeight + 10);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Sales.Total_Returned_Amount')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + (this.SaleRetProdList.tot_inv_amt).toFixed(this.country_dec),  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Sales.total_returned_qty')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + (this.SaleRetProdList.tot_qty).toFixed(this.country_dec),  valueXPos, headerHeight + 20);
  
      if(this.SaleRetProdList.van){
        doc.setFontSize(10);
        doc.text('Van', lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.SaleRetProdList.van.van_name,  valueXPos, headerHeight + 25);
      }
  
    doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, this.heads, data, null);
  
    doc.save(`Sales Returned Product List.pdf`);

  });

  this.exportLoader = false;

}

}
