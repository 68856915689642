import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Repprddet } from '../../../../../model/report/repprddet.model';
import { Subcatagory } from '../../../../../model/subcatagory.model';
import { Feature } from '../../../../../model/feature.model';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-godown-transaction',
  templateUrl: './godown-transaction.component.html',
  styleUrls: ['./godown-transaction.component.css']
})
export class GodownTransactionComponent implements OnInit {
  fileName = 'Godown_stock_transaction.xlsx';

  nul: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  prd_id: any;
  prd_cat_id: any;
  gd_id: any;
  add_by: any;
  period_type: any;
  date1: any;
  date2: any;
  gd_id_to: any;

  products_all: string[];
  catagories: string[];
  subcatagories: string[];
  mfs: string[];
  cat_id: number;
  prodsumms: string[];
  prodsummscat: string[];
  unit_all: string[];
  features: string[];
  baseunit: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  stkprd_all: string[];
  slNo: number;
  mrpDef = 0;
  uspDef = 0;
  mrpExp = 'all';
  calc_mode: string;
  totalproducts: number;
  totalstock: number;
  totalpurchase: any;
  totcats: number;
  remark: string;
  godown_all: any;
  values: string[];
  totalRes: any;
  godownRep: any;
  total_products: any;
  pageLoadingImg: boolean;
  repDate: any;
  userType: any;
  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];
  exportLoader: boolean;
  ExpgodownRep: any;
  ExptotalRes: any;
  Expprodsumms: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  country_dec: number=2;
  cur_lang: string;
  filter_branch_id: any;
  branch_all: any;
  PrdName: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    // this.filter_branch_id = 1;
    this.getAllBranch();

    this.country_dec=this.coreService.setDecimalLength();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.userType = this.coreService.getUserData('user_type');
    this.pageLoadingImg = true;
    this.reeadFeat();
    this.godownRep = [];
    let searchval = new FormData();
    this.apiService.godownTrans(searchval, 1).subscribe((res: Repprddet) => {
      this.pageLoadingImg = false;
      this.prodsumms = res['data']['data'];
      this.totalRes = res['data']['alldetais'];
      // this.godownRep = res['data']['alldetais']['godownRep'];
      if (res['data']['alldetais']['godownRep']) {
        this.godownRep = res['data']['alldetais']['godownRep'];
      } else {
        this.godownRep = '';
      }
      if (this.totalpurchase) { this.totalpurchase = this.totalpurchase.toFixed(2); }
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.slNo = 0;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.userType = this.coreService.getUserData('user_type');

  }


  ngAfterContentInit() {

    this.searchForm('');
    this.searchGdwn('');

  }

  ngOnDestroy() {
    $('.close').click();

  }


  //form search  for category
  searchForm(search: string) {
    let searchval = new FormData();
    searchval.append("cat_name", search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  //product form search 
  searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getPrdRepkbyName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  //Remove Name Suggestions

  removeName() {
    this.catagories = [];
    this.reeadFeat();
  }


  selectCat(cat_id: number) {
    this.cat_id = cat_id;
  }



  pageNext(formdata: { value: any; }, pageno: any) {
    $('.resultdata').hide();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.mrp == 1)
      formdata.value.avg_rate = undefined;

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.godownTrans(formdata.value, pageno).subscribe((res: Repprddet) => {
      if (res['data'] != '') {
        this.pageLoadingImg = false;
        $('.resultdata').show();
        if (res['data']['alldetais']['godownRep']) {
          this.godownRep = res['data']['alldetais']['godownRep'];
        } else {
          this.godownRep = '';
        }

        this.repDate = res['data'].Date;

        this.totalRes = res['data']['alldetais'];
        this.prodsumms = res['data']['data'];
        if (this.totalpurchase) {
          this.totalpurchase = this.totalpurchase.toFixed(2);
        }
        this.curpage = res['data']['current_page'];
        this.lastpage = res['data']['last_page'];
        this.pgEnd(this.curpage, this.lastpage);
        this.pgStart(this.curpage);
      } else {
        this.pageLoadingImg = false;
        this.prodsumms = [];

        this.totalRes = {
          total_products: 0,
          total_stock: 0,
          total_categories: 0,
          total_purchase_amount: 0

        }


      }
    });
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.No','Common.Yes','Common.With_Discount','Common.Without_Discount','Common.summary','Common.detailed','Common.cash','HOME.credit']).subscribe((res: string) => {    
     
 
      this.filter_types = [
        
        { id: 't', name: res['Common.today'] },
        { id: 'ld', name: res['Common.last_day'] },
        { id: 'lw', name: res['Common.last_week'] },
        { id: 'lm', name: res['Common.last_maonth'] },
        { id: 'ly', name: res['Common.last_yr'] },
        { id: 'c', name: res['Common.cust_date'] },
        { id: '', name: res['Common.all'] }
            
          ];
    });

  


  }
  selectSubCats(catid: number) {
    this.apiService.getAllSubCatbyId(catid).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];
      if (this.changeBox)
        this.changeBox = false;
      else
        this.changeBox = true;

    });
  }

  //form search  manufacture
  searchManf(search: string) {
    let searchval = new FormData();
    searchval.append("manftr_comp_name", search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.mfs = res['data'];
    });
  }

  //form search unit
  searchUnit(search: string) {
    let searchval = new FormData();
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];
    });

  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      this.godown_all.push({ "gd_id": 0, 'gd_name': 'SHOP' });
    });

  }

  //form search
  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.values = res['data'];
    });
  }


  // filter  types
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom' },
    { id: '', name: 'All' }

  ];

  filter_rate = [
    { id: 0, name: 'Purchase Rate' },
    { id: 1, name: 'Sales Rate -(M.R. P)' }
  ];
  filter_prate = [
    { id: 0, name: 'Last Purchase Rate' },
    { id: 1, name: 'Avg. Purchase rate' }
  ];

  expity_rate = [
    { id: 'all', name: 'All' },
    { id: 'no', name: 'No Expiry' },
    { id: 'has', name: 'Has Expiry' },
    { id: 'expired', name: 'Expired' },
    { id: 'not_expired', name: 'Not Expired' },
    { id: 'custom', name: 'Custom Date' }
  ];



  selectRate(rateid: number) {
    if (rateid == 1) { $('#useprate').hide(); }
    else {
      $('#useprate').show();
    }
  }

  selectExp(expid: string) {
    if (expid == 'custom') {
      $('#expiry_val').show();
      $('#expiry_val2').show();
    }
    else {
      $('#expiry_val').hide();
      $('#expiry_val2').hide();
    }
  }

  selectType(id: string) {
    if (id == 'c') {
      $('#val1').show();
      $('#val2').show();
    } else {
      $('#val1').hide();
      $('#val2').hide();

    }

  }
  selectRateFilter(rateid: string) {
    if (rateid == 'between') {
      $('#rate_val2').show();
    }
    else {
      $('#rate_val2').hide();
    }
  }

  // Barcode and Ean
  stk_ean_barcode = [
    { id: 'all', name: 'All' },
    { id: 'between', name: 'Custom' }
  ];

  selectBarcodeFilter(id: string) {
    if (id == 'between') {
      $('#bar_val2').show();
    }
    else {
      $('#bar_val2').hide();
    }
  }

  selectStkEanBarcode(id: string) {
    if (id == 'between') {
      $('#ean_key').show();
    }
    else {
      $('#ean_key').hide();
    }
  }


  reeadFeat() {
    this.apiService.readFeat().subscribe((res: Feature[]) => {
      this.features = res['data'];

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;
  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  export(formdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    if (formdata.value.mrp == 1)
      formdata.value.avg_rate = undefined;

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.godownTrans(formdata.value, 1).subscribe((res: Repprddet) => {
      if (res['data'] != '') {

        if (res['data']['alldetais']['godownRep']) {
          this.ExpgodownRep = res['data']['alldetais']['godownRep'];
        } else {
          this.ExpgodownRep = '';
        }

        this.repDate = res['data'].Date;
        this.ExptotalRes = res['data']['alldetais'];
        
        this.Expprodsumms = res['data']['data'];
        
      
      } else {
        this.Expprodsumms = [];

        this.ExptotalRes = {
          total_products: 0,
          total_stock: 0,
          total_categories: 0,
          total_purchase_amount: 0

        }


      }
      setTimeout(() => {
        this.exportexcel();
      }, 3000);

      formdata.value.export = '';


    });



  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

    // debugger;
    formdata.value.export = 'export';
    if (formdata.value.mrp == 1)
      formdata.value.avg_rate = undefined;

    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.godownTrans(formdata.value, 1).subscribe((res: Repprddet) => {
      if (res['data'] != '') {

        if (res['data']['alldetais']['godownRep']) {
          this.ExpgodownRep = res['data']['alldetais']['godownRep'];
        } else {
          this.ExpgodownRep = '';
        }

        this.repDate = res['data'].Date;
        this.ExptotalRes = res['data']['alldetais'];
        
        this.Expprodsumms = res['data']['data'];
        
      
      } else {
        this.Expprodsumms = [];

        this.ExptotalRes = {
          total_products: 0,
          total_stock: 0,
          total_categories: 0,
          total_purchase_amount: 0

        }


      }
      const heads = [
        this.translate.instant('HOME.NAME'),
        this.translate.instant('Table.barcode'),
        this.translate.instant('Table.date'),
        this.translate.instant('transaction.Tran_No'),
        this.translate.instant('HOME.STOCK') + '\n' + this.translate.instant('Table.qty'),
        this.translate.instant('HOME.SALES') + '\n' + this.translate.instant('Table.rate'),
        this.translate.instant('HOME.SALES') + '\n' + this.translate.instant('Table.amnt'),
        this.translate.instant('Common.from'),
        this.translate.instant('Common.to')
      ];

      if(this.userType =='ADMIN' || this.userType =='BRANCH ADMIN'){
        heads.splice(5,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.rate'))
        heads.splice(6,0, this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt'))
      }

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.Expprodsumms.forEach(item=>{
          if(this.translate.currentLang == "Arabic"){this.PrdName = item.prd_alias}else{this.PrdName = item.prd_name}

          const arr=[
            this.PrdName,
            item.prd_barcode,
            this.datePipe.transform(item.gsl_date, 'dd/MM/yyyy'),
            item.gsl_tran_inv_id,
            (item.gsl_qty).toFixed(this.country_dec),
            (item.sales_rate * item.gsl_qty).toFixed(this.country_dec)
          ]

          if(this.userType =='ADMIN' || this.userType =='BRANCH ADMIN'){
            arr.splice(5,0, (item.pur_rate).toFixed(this.country_dec))
            arr.splice(6,0, (item.pur_amt).toFixed(this.country_dec))
          }

          if(item.sales_rate !== null){
            arr.splice(7,0, (item.sales_rate).toFixed(this.country_dec))
          } else {
            arr.splice(7,0, '0')
          }

          if(item.gd_from == null){
            arr.splice(9,0, this.translate.instant('HOME.SHOP'))
          } else {
            arr.splice(9,0, item.gd_from) 
          }

          if(item.gd_from == null){
            arr.splice(10,0, this.translate.instant('HOME.SHOP'))
          } else {
            arr.splice(10,0, item.gd_to) 
          }

          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Godown.Godown_Stock_Transaction')}`,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Godown.Total_No_of_Items')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalRes.total_products,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.STOCK')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.totalRes.total_stock, '', '', '1.1-1'),  valueXPos, headerHeight + 25);

        if(this.userType =='ADMIN' || this.userType =='BRANCH ADMIN'){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + this.currencyPipe.transform( this.totalRes.total_purchase_amount, '', '', '1.1-1'),  valueXPos, headerHeight + 30);
        }
        
        if(this.godownRep){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.GODOWN')}`, lblXPos, headerHeight + 35);
          doc.setFontSize(10);
          doc.text(':  ' + this.godownRep,  valueXPos, headerHeight + 35);
        }

        doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
    
       
        doc.save(`Godown Stock-Transaction.pdf`);

        this.exportLoader = false;

      formdata.value.export = '';


    });
  }
  
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res:any) => {
      this.branch_all = res.data;
      // this.filter_branch_id = res.selected;
  
      // console.log(  this.branch_all );
    //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }
  
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res:any) => {
      this.branch_all = res.data;
      // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
    });
  }

}
