import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  elseBlocknostart: any;
  elseBlocknoend: any;
  rep_type:any;
  date1: any;
  date2: any;
  period_type: any;
  van_tran_filter: any;
  trans_val1: any;
  trans_val2: any;
  prd_name: any;
  prd_cat_id: any;
  manfact_id: any;
  van_id: any;
  usersel: any;
  filter: any;
  modal: any;
  reportType:any;
  tran_id: number;
  vantran_purch_price: number;
  prd_barcode: String;
  vantransub_qty: number;
  vantran_purch_amount: number;
  vantransub_unit_id: number;
  total_qty = 0;
  NgForm: any;
  total_amount = 0;
  sel_gd_id = 0;
  tranArray: any;
  slno = 0;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  resultobj: {};
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  category:string[];
  proditems:any;
  totalProducts:number;
  totalCats:number;
  totalQty:number;
  totalRate = 0;
  categories:string[];
  pageLoadingImg:boolean;
  categorytotal:string[];
  company:string[];
  vans:string[];
  values:string;
  dataLength :number;
  items=[];
  searchProducts:any;
  alldetais:any;
  prd_id:any;
  reportBy:any;
  repDate:any;
  branch_all: any;
  godowns: any;
  datastatus: any;
  up: any;
  userType: any;
  subcatagories: any;
  repDispType = 'summary';
  exp_alldetais : any;
  exp_proditems : any;
  exp_repDate : any;
  fileName = 'received_products.xlsx';
  exportLoader: boolean;
  branch_display_name: any;
  branch_display_code: any;
  branch_name: any;
  branch_address:any;
  country_dec: number=2;
  cur_lang: string;
  filter_branch_id: any;
  PrdNAme: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })

    
    this.getAllBranch();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.readVanTransfers();  
    this.up = this.coreService.getUserPrivilage();
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.userType = this.coreService.getUserData('user_type');
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','Common.detailed']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.report_dispaly_type = [
        { id: 'summary', name:  res['Common.summary'] },
        { id: 'detailed', name: res['Common.detailed'] },
      ];
    
    });
  }

  readVanTransfers() {
    this.listAllBranches();
    this.listGodowns();
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.apiService.readbranchTransfersItemReceivedReport(searchval, 1).subscribe((res) => {
      this.pageLoadingImg = false; 
      this.alldetais = res['data']['alldetais'];     
      this.proditems = res['data']['data']; 

      this.datastatus= this.proditems.length;
      this.curpage = res['data']['current_page'];  
      this.lastpage= res['data']['last_page']; 
      this.totalCats = res['data']['total_cats'];    
      this.reportType = res['data']['reportType']; 
      this.reportBy = res['data']['reportBy'];   
      this.repDate = res['data']['Date'];
          
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);   
    });
  }
  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      console.log(res);
      this.godowns = res['data'];

    });

  }

// filter  types

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' }   
  ];
  
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  rept_type = [
    { id: 1, name: 'Transfer & Returns' },
    { id: 2, name: 'Transfer' },
    { id: 3, name: 'Returns' },
  ];
  report_dispaly_type = [
    { id: 'summary', name: 'Summary' },
    { id: 'detailed', name: 'Detailed' },
  ];

  listAllBranches() {
    let searchval = new FormData();

      this.apiService.getAllBranchesforTransfer().subscribe((res) => {
      this.branch_all = res['data'];
      this.userType = this.coreService.getUserData('user_type');
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });

  }
  ngAfterContentInit(){
    this.searchCat('');
    this.searchVans('');
    this.searchComp('');
  }
  ngOnDestroy(){
    $('.close').click();
 
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; }, pageno:any)
  {  
   formdata.value.filter =1;
  this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
  this.apiService.readbranchTransfersItemReceivedReport(formdata.value,pageno).subscribe((res) => {
    this.pageLoadingImg = false;             
    this.alldetais = res['data']['alldetais'];     
    this.proditems = res['data']['data']; 
    this.curpage = res['data']['current_page'];  
    this.lastpage= res['data']['last_page']; 
    this.totalCats = res['data']['total_cats'];    
    this.reportType = res['data']['reportType']; 
    this.reportBy = res['data']['reportBy'];   
    this.repDate = res['data']['Date'];
    

    this.pgEnd(this.curpage, this.lastpage);
    this.pgStart(this.curpage);      
  });
  }
 
    //form search  for category
    searchCat(search: string) {
      let searchval = new FormData();
      searchval.append("cat_name", search);
      this.apiService.getCatbyName(searchval).subscribe((res) => {
        this.categories = res['data'];     
      });  
    }

    searchComp(search: string) {
      const searchval = new FormData();
      searchval.append('manftr_comp_name', search);
      this.apiService.getManfbyName(searchval).subscribe((res) => {
        this.company = res['data'];
      });
  
    }

    searchVans(search: string) {
      const searchval = new FormData();
      searchval.append('van_name', search);
      this.apiService.searchVans(searchval).subscribe((res) => {
        this.vans = res['data'];
      });
  
    }

 // search product
 selectProd(name: string) {

  const searchval = new FormData();
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    searchval.append('alias_search', '1');
  }
  searchval.append('prod_name', name);
  this.apiService.getProdByName(searchval).subscribe((res) => {
    this.searchProducts = res.data;
  });
}
filter_refnum = [
  { id: 'between', name: 'Between' },
  { id: '<', name: '<' },
  { id: '>', name: '>' },
  { id: '=', name: '=' },
  { id: '<=', name: '<=' },
  { id: '>=', name: '>=' },
  { id: '=', name: '=' },
];
      //form search
 searchUser(search: string)
 {
  let searchval = new FormData();      
       searchval.append("usr_name", search);    
        this.apiService.getUserByName(searchval).subscribe((res) => {
         this.values = res['data'];                  
        });
 }

 selectSubCats(catid: number) {
  this.apiService.getAllSubCatbyId(catid).subscribe((res: any) => {
    this.subcatagories = res['data'];
    

  });
}
exportexcel(): void {
  /* table id is passed over here */
  let element = document.getElementById('export-group');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);
  this.exportLoader = false;

}



exportEXCL(formdata: { value: any; }) {


  this.exportLoader = true;

  formdata.value.export = 'export';

  formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
  formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;

  this.apiService.readbranchTransfersItemReceivedReport(formdata.value, 1).subscribe((res: any) => {
    this.exp_alldetais = res['data']['alldetais'];     
    this.exp_proditems = res['data']['data']; 
    this.exp_repDate = res['data']['Date'];

    setTimeout(() => {
      this.exportexcel();
    }, 3000);



  });

}

// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;

  formdata.value.export = 'export';

  formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
  formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;

  this.apiService.readbranchTransfersItemReceivedReport(formdata.value, 1).subscribe((res: any) => {
    this.exp_alldetais = res['data']['alldetais'];     
    this.exp_proditems = res['data']['data']; 
    this.exp_repDate = res['data']['Date'];

    const heads = [
   
    ];

    if(this.repDispType=='summary'){
      heads.splice(0,0, this.translate.instant('Table.itm_name'))
      heads.splice(1,0, this.translate.instant('Table.rate'))
      heads.splice(2,0, this.translate.instant('Table.qty'))
      heads.splice(3,0, this.translate.instant('Table.amnt'))
    } else if(this.repDispType=='detailed') {
      heads.splice(0,0, this.translate.instant('Table.itm_name'))
      heads.splice(1,0, this.translate.instant('Table.barcode'))
      heads.splice(2,0, this.translate.instant('Common.category'))
      heads.splice(3,0, this.translate.instant('Common.sub_category'))
      heads.splice(4,0, this.translate.instant('Sales.Avg_Rate'))
      heads.splice(5,0, this.translate.instant('Common.received') + '\n' + this.translate.instant('Table.qty'))
      heads.splice(6,0, this.translate.instant('Common.received') + '\n' + this.translate.instant('Table.amnt'))
    }

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];
  
      this.exp_proditems.forEach(item=>{

        if(this.repDispType=='summary'){
          item.category.forEach(item1=>{

            data.push([
              item1.cat_name,
              (item1.rate).toFixed(this.country_dec),
              this.currencyPipe.transform(item1.qty, '', '', '1.1-1'),
              (item1.amount).toFixed(this.country_dec)
            ])
          })
  
          item.items.forEach(item1=>{
            if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item1.prd_alias}else{this.PrdNAme =  item1.prd_name}
            data.push([
              this.PrdNAme,
              (item1.totalAmount/item1.totQty).toFixed(this.country_dec),
              this.currencyPipe.transform(item1.totQty, '', '', '1.1-1'),
              ((item1.totalAmount)).toFixed(this.country_dec)
            ])
          })
        } else if(this.repDispType=='detailed'){
          item.items.forEach(item1=>{
            if(this.translate.currentLang == "Arabic"){this.PrdNAme =  item1.prd_alias}else{this.PrdNAme =  item1.prd_name}
            data.push([
              this.PrdNAme,
              item1.prd_barcode,
              item1.cat_name,
              item1.subcat_name,
              (item1.totalAmount/item1.totQty).toFixed(this.country_dec),
              this.currencyPipe.transform(item1.totQty, '', '', '1.1-1'),
              ((item1.totalAmount)).toFixed(this.country_dec)
            ])
          })
        }

      })
  
      let doc = this.excelService.getPdfObj();
  
      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');
  
      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      if(this.exp_repDate){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
        doc.setFontSize(10);
        doc.text(':  ' + this.exp_repDate.date1 + '   to   ' + this.exp_repDate.date2  ,  valueXPos, headerHeight + 10);
      }
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Vat.Received_Products')}`,  valueXPos, headerHeight + 15);
  
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + this.exp_alldetais.total_products,  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.totl_category')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + this.exp_alldetais.total_categories,  valueXPos, headerHeight + 25);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Branch.total_received_qty')}`, lblXPos, headerHeight + 30);
      doc.setFontSize(10);
      doc.text(':  ' + this.currencyPipe.transform(this.exp_alldetais.total_qty, '', '', '1.1-1'),  valueXPos, headerHeight + 30);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.total_amount')}`, lblXPos, headerHeight + 35);
      doc.setFontSize(10);
      doc.text(':  ' + (this.exp_alldetais.total_purchase_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 35);

      if(this.reportBy){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.VAN')}`, lblXPos, headerHeight + 40);
        doc.setFontSize(10);
        doc.text(':  ' + this.reportBy,  valueXPos, headerHeight + 40);
      }

      if(this.reportType){
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.rpt_type')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ' + this.reportType,  valueXPos, headerHeight + 45);
      }
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 50, lblXPos, heads, data, null);
  
     
      doc.save(`Recieved Product.pdf`);

      this.exportLoader = false;

  });

}
getAllBranch() {
  this.apiService.readAllBranch().subscribe((res:any) => {
    this.branch_all = res.data;
    this.filter_branch_id = res.selected;

    // console.log(  this.branch_all );
  //  this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

searchBranch(search: string) {
  let searchval = new FormData();
  searchval.append("branch_name", search);
  this.apiService.getBranchByName(searchval).subscribe((res:any) => {
    this.branch_all = res.data;
    // this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
  });
}

}
