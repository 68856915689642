import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ReportDashComponent } from "./report-dash/report-dash.component";
import { RepProductComponent } from "./report-dash/rep-product/rep-product.component";
import { RepCatagoryComponent } from "./report-dash/rep-product/rep-catagory/rep-catagory.component";
import { RepProductDetailsComponent } from "./report-dash/rep-product/rep-product-details/rep-product-details.component";
import { RepProductSummaryComponent } from "./report-dash/rep-product/rep-product-summary/rep-product-summary.component";
import { RepManSummaryComponent } from "./report-dash/rep-product/rep-man-summary/rep-man-summary.component";
import { RepStockSummaryComponent } from "./report-dash/rep-product/rep-stock-summary/rep-stock-summary.component";
import { ManfDetailsComponent } from "./report-dash/rep-product/manf-details/manf-details.component";
import { RepStockComponent } from "./report-dash/rep-stock/rep-stock.component";
import { RepOpeningstockComponent } from "./report-dash/rep-stock/rep-openingstock/rep-openingstock.component";
import { RepStockbyperiodComponent } from "./report-dash/rep-stock/rep-stockbyperiod/rep-stockbyperiod.component";
import { RepPurchaseComponent } from "./report-dash/rep-purchase/rep-purchase.component";
import { PurchaseSummaryComponent } from "./report-dash/rep-purchase/purchase-summary/purchase-summary.component";
import { PurchaseDetailsComponent } from "./report-dash/rep-purchase/purchase-details/purchase-details.component";
import { PurchaseInvoiceComponent } from "./report-dash/rep-purchase/purchase-invoice/purchase-invoice.component";
import { ProductInvoiceComponent } from "./report-dash/rep-purchase/product-invoice/product-invoice.component";
import { RepGodownComponent } from "./report-dash/rep-godown/rep-godown.component";
import { GodownStockSummaryComponent } from "./report-dash/rep-godown/godown-stock-summary/godown-stock-summary.component";
import { GodownTransferComponent } from "./report-dash/rep-godown/godown-transfer/godown-transfer.component";
import { GodownProductComponent } from "./report-dash/rep-godown/godown-product/godown-product.component";
import { GodownTransactionComponent } from "./report-dash/rep-godown/godown-transaction/godown-transaction.component";
import { VanTransferItemComponent } from "./report-dash/rep-van/van-transfer-item/van-transfer-item.component";
import { VanTransferInvoiceComponent } from "./report-dash/rep-van/van-transfer-invoice/van-transfer-invoice.component";
import { RepVanComponent } from "./report-dash/rep-van/rep-van.component";
import { VanTransferDatewiseComponent } from "./report-dash/rep-van/van-transfer-datewise/van-transfer-datewise.component";
import { PurchaseReturnSummaryComponent } from "./report-dash/rep-purchase/purchase-return-summary/purchase-return-summary.component";
import { PurchaseReturnProductComponent } from "./report-dash/rep-purchase/purchase-return-product/purchase-return-product.component";
import { ProductionSummComponent } from "./report-dash/rep-production/production-summ/production-summ.component";
import { ProductionDashComponent } from "./report-dash/rep-production/production-dash/production-dash.component";
import { ProductionDetailsComponent } from "./report-dash/rep-production/production-details/production-details.component";
import { ProductionIncredientsComponent } from "./report-dash/rep-production/production-incredients/production-incredients.component";
import { ProductionCommComponent } from "./report-dash/rep-production/production-comm/production-comm.component";
import { ProductionProductComponent } from "./report-dash/rep-production/production-product/production-product.component";
import { PurchaseAndReturnReportSummaryComponent } from "./report-dash/rep-purchase/purchase-and-return-report-summary/purchase-and-return-report-summary.component";
import { RepStockExpiryComponent } from "./report-dash/rep-stock/rep-stock-expiry/rep-stock-expiry.component";
import { SalesSammaryComponent } from "./report-dash/rep-sales/sales-sammary/sales-sammary.component";
import { RepSalesComponent } from "./report-dash/rep-sales/rep-sales.component";
import { TestComponent } from "./report-dash/test/test.component";
import { SaleProdInvoiceComponent } from "./report-dash/rep-sales/sale-prod-invoice/sale-prod-invoice.component";
import { PrintSalesInvoiceComponent } from "./report-dash/rep-sales/print-sales-invoice/print-sales-invoice.component";
import { SalesProdRepComponent } from "./report-dash/rep-sales/sales-prod-rep/sales-prod-rep.component";
import { LedgerDetailComponent } from "./report-dash/rep-ledger/ledger-detail/ledger-detail.component";
import { ReportCompanyComponent } from "./report-dash/report-company/report-company.component";

import { AccDaybookComponent } from "./report-dash/rep-accounts/acc-daybook/acc-daybook.component";
import { DailySummaryComponent } from "./report-dash/rep-accounts/daily-summary/daily-summary.component";
import { CustomerDetailsComponent } from "./report-dash/rep-accounts/customer-details/customer-details.component";
import { RepVatInvoiceComponent } from "./report-dash/rep-vat-invoice/rep-vat-invoice.component";

import { InputVatSummaryComponent } from "./report-dash/rep-vat-invoice/input-vat-summary/input-vat-summary.component";
import { InputExpenseVatSummaryComponent } from "./report-dash/rep-vat-invoice/input-expense-vat-summary/input-expense-vat-summary.component";
import { DailyVatSummaryComponent } from "./report-dash/rep-vat-invoice/daily-vat-summary/daily-vat-summary.component";

import { OutputVatSummaryComponent } from "./report-dash/rep-vat-invoice/output-vat-summary/output-vat-summary.component";

import { RepBranchTransferComponent } from "./report-dash/rep-branch-transfer/rep-branch-transfer.component";

import { TransferComponent } from "./report-dash/rep-branch-transfer/transfer/transfer.component";

import { ReceiptComponent } from "./report-dash/rep-branch-transfer/receipt/receipt.component";

import { SalesVoidSummaryComponent } from "./report-dash/rep-sales/sales-void-summary/sales-void-summary.component";

import { LedgerListComponent } from "./report-dash/rep-accounts/ledger-list/ledger-list.component";

import { RepGrpSummaryComponent } from "./report-dash/rep-grp-summary/rep-grp-summary.component";

import { AccGrpSummaryComponent } from "./report-dash/rep-grp-summary/acc-grp-summary/acc-grp-summary.component";
import { TrialBalanceComponent } from "./report-dash/rep-accounts/trial-balance/trial-balance.component";
import { OpBalanceSummaryComponent } from "./report-dash/rep-accounts/op-balance-summary/op-balance-summary.component";

import { SummaryComponent } from "./report-dash/rep-branch-transfer/summary/summary.component";
import { ReceiptsummaryComponent } from "./report-dash/rep-branch-transfer/receiptsummary/receiptsummary.component";
import { BranchWiseComponent } from "./report-dash/rep-stock/branch-wise/branch-wise.component";
import { InvoiceDueComponent } from "./report-dash/rep-sales/invoice-due/invoice-due.component";
import { VerificationOfBalanceComponent } from "./report-dash/rep-accounts/verification-of-balance/verification-of-balance.component";
import { RepDamageSummaryComponent } from "./report-dash/rep-product/rep-damage-summary/rep-damage-summary.component";

import { ProfitAndLossComponent } from "./report-dash/rep-accounts/profit-and-loss/profit-and-loss.component";
import { BalanceSheetComponent } from "./report-dash/rep-accounts/balance-sheet/balance-sheet.component";

import { SalesandreturnComponent } from "./report-dash/rep-sales/saleswithreturn/salesandreturn/salesandreturn.component";
import { EditlogComponent } from "./report-dash/rep-purchase/editlog/editlog.component";
import { CustomeragingComponent } from "./report-dash/rep-sales/customeraging/customeraging.component";
import { ReturnedproductsComponent } from "./report-dash/rep-sales/returnedproducts/returnedproducts.component";
import { RepAuditorComponent } from "./report-dash/rep-auditor/rep-auditor.component";
import { CashCreditPurchaseComponent } from "./report-dash/rep-auditor/cash-credit-purchase/cash-credit-purchase.component";

import { CustomersalesComponent } from "./report-dash/rep-sales/customersales/customersales.component";
import { SalesCashCreditComponent } from "./report-dash/rep-auditor/sales-cash-credit/sales-cash-credit.component";
import { BranchTransferComponent } from "./report-dash/rep-auditor/branch-transfer/branch-transfer.component";
import { ExpensesReportComponent } from "./report-dash/rep-auditor/expenses-report/expenses-report.component";
import { BanksComponent } from "./report-dash/rep-auditor/banks/banks.component";
import { EmployeeComponent } from "./report-dash/rep-auditor/employee/employee.component";
import { VatDailySummaryComponent } from "./report-dash/rep-vat-invoice/vat-daily-summary/vat-daily-summary.component";
import { SaleDetailRepComponent } from "./report-dash/rep-auditor/sale-detail-rep/sale-detail-rep.component";
import { RepMissingSummaryComponent } from "./report-dash/rep-stock/rep-missing-summary/rep-missing-summary.component";
import { RepExcessSummaryComponent } from "./report-dash/rep-stock/rep-excess-summary/rep-excess-summary.component";
import { RepLowStockAlertComponent } from "./report-dash/rep-stock/rep-low-stock-alert/rep-low-stock-alert.component";
import { RepExcessStockAlertComponent } from "./report-dash/rep-stock/rep-excess-stock-alert/rep-excess-stock-alert.component";
import { RepBranchTranInvBasedComponent } from "./report-dash/rep-branch-transfer/rep-branch-tran-inv-based/rep-branch-tran-inv-based.component";
import { RepBranchReciInvBasedComponent } from "./report-dash/rep-branch-transfer/rep-branch-reci-inv-based/rep-branch-reci-inv-based.component";
import { RepStockVerifySummaryComponent } from "./report-dash/rep-stock/rep-stock-verify-summary/rep-stock-verify-summary.component";
import { RepPurchaseDetailSummaryComponent } from "./report-dash/rep-purchase/rep-purchase-detail-summary/rep-purchase-detail-summary.component";
import { VanStockTrackingComponent } from "./report-dash/rep-van/van-stock-tracking/van-stock-tracking.component";
import { VanDistributorsRepComponent } from "./report-dash/rep-van/van-distributors-rep/van-distributors-rep.component";
import { RepItemMovmentComponent } from "./report-dash/rep-stock/rep-item-movment/rep-item-movment.component";
import { RepStockVerificationInvBasedComponent } from "./report-dash/rep-stock/rep-stock-verification-inv-based/rep-stock-verification-inv-based.component";
import { InvDueRecSummaryComponent } from "./report-dash/rep-sales/inv-due-rec-summary/inv-due-rec-summary.component";
import { RepCashBankBookComponent } from "./report-dash/rep-accounts/rep-cash-bank-book/rep-cash-bank-book.component";
import { GodownDetailsComponent } from "./report-dash/rep-godown/godown-details/godown-details.component";
import { RepCustomersComponent } from "./report-dash/rep-customers/rep-customers.component";
import { CashCounterReportComponent } from "./report-dash/rep-sales/cash-counter-report/cash-counter-report.component";
import { RepTopCustomersComponent } from "./report-dash/rep-top-customers/rep-top-customers.component";
import { RepTopProductsComponent } from "./report-dash/rep-top-products/rep-top-products.component";
import { VanDetailedRepComponent } from './report-dash/rep-van/van-detailed-rep/van-detailed-rep.component';
import { BranchDetailedRepComponent } from './report-dash/rep-branch-transfer/branch-detailed-rep/branch-detailed-rep.component';
import { ItemTrackingRepComponent } from './report-dash/rep-product/item-tracking-rep/item-tracking-rep.component';
import { RepExtTransferComponent } from './report-dash/rep-ext-transfer/rep-ext-transfer.component';
import { ExtTransferInvBasedComponent } from './report-dash/rep-ext-transfer/ext-transfer-inv-based/ext-transfer-inv-based.component';
import { ExtTransferDetComponent } from './report-dash/rep-ext-transfer/ext-transfer-det/ext-transfer-det.component';
import { ExtReceiptInvBasedComponent } from './report-dash/rep-ext-transfer/ext-receipt-inv-based/ext-receipt-inv-based.component';
import { ExtReceiptDetComponent } from './report-dash/rep-ext-transfer/ext-receipt-det/ext-receipt-det.component';
import { ExpenseTaxPaymentSummaryComponent } from './report-dash/rep-accounts/expense-tax-payment-summary/expense-tax-payment-summary.component';
import { ItemMovementDetailsComponent } from './report-dash/rep-stock/item-movement-details/item-movement-details.component';
import { SalesAgentReportComponent } from './report-dash/rep-sales/sales-agent-report/sales-agent-report.component';
import { VanTopsheetRepComponent } from "./report-dash/rep-van/van-topsheet-rep/van-topsheet-rep.component";
import { UnitListComponent } from "src/app/view/product/unit-list/unit-list.component";
import { StockUnitReportComponent } from "./report-dash/rep-stock/stock-unit-report/stock-unit-report.component";
import { VanStockTrakingUnitwiseComponent } from "./report-dash/rep-van/van-stock-traking-unitwise/van-stock-traking-unitwise.component";
import { VanMissingStockComponent } from "./report-dash/rep-van/van-missing-stock/van-missing-stock.component";
import { VanExcessStockRepComponent } from "./report-dash/rep-van/van-excess-stock-rep/van-excess-stock-rep.component";
import { CostCategoryComponent } from "./report-dash/rep-accounts/cost-category/cost-category.component";
import { ItemUnitwiseTrackingComponent } from "./report-dash/rep-stock/item-unitwise-tracking/item-unitwise-tracking.component";
import { SalesQuoatationSummaryComponent } from "./report-dash/rep-sales/sales-quoatation-summary/sales-quoatation-summary.component";
import { DeliveryChallanRepComponent } from "./report-dash/rep-sales/delivery-challan-rep/delivery-challan-rep.component";
import { DeliveryChallanItemwiseComponent } from "./report-dash/rep-sales/delivery-challan-itemwise/delivery-challan-itemwise.component";
import { GodownStockDatewiseComponent } from "./report-dash/rep-godown/godown-stock-datewise/godown-stock-datewise.component";
import { RepWarrantySummaryComponent } from "./report-dash/rep-stock/rep-warranty-summary/rep-warranty-summary.component";
import { SalesOrderRepComponent } from "./report-dash/rep-sales/sales-order-rep/sales-order-rep.component";
import { PurchaseOrderRepComponent } from "./report-dash/rep-purchase/purchase-order-rep/purchase-order-rep.component";
import { CreditNoteRepComponent } from "./report-dash/rep-accounts/credit-note-rep/credit-note-rep.component";
import { DebitNoteRptComponent } from "./report-dash/rep-accounts/debit-note-rpt/debit-note-rpt.component";
import { PurchInvoiceDueComponent } from "./report-dash/rep-purchase/purch-invoice-due/purch-invoice-due.component";
import { SupplierAgingRptComponent } from "./report-dash/rep-accounts/supplier-aging-rpt/supplier-aging-rpt.component";
import { RecieptSummaryComponent } from "./report-dash/rep-accounts/reciept-summary/reciept-summary.component";
import { CustMovmentRptComponent } from "./report-dash/rep-accounts/cust-movment-rpt/cust-movment-rpt.component";
import { InvoiceDuePaymentSummaryComponent } from "./report-dash/rep-purchase/invoice-due-payment-summary/invoice-due-payment-summary.component";
import { RepTopSuppliersComponent } from "./report-dash/rep-top-suppliers/rep-top-suppliers.component";
import { BatchWiseComponent } from "./report-dash/rep-stock/batch-wise/batch-wise.component";
import { RepBatchstockbyperiodComponent } from "./report-dash/rep-stock/rep-batchstockbyperiod/rep-batchstockbyperiod.component";
import { RepCashBookComponent } from "./report-dash/rep-accounts/rep-cash-book/rep-cash-book.component";
import { VanSaleRepComponent } from "./report-dash/rep-van/van-sale-rep/van-sale-rep.component";
import { VanItemwiseRepComponent } from "./report-dash/rep-van/van-itemwise-rep/van-itemwise-rep.component";
import { VisitingRepComponent } from "./report-dash/rep-van/visiting-rep/visiting-rep.component";
import { RepProductCommisionComponent } from "./report-dash/rep-product/rep-product-commision/rep-product-commision.component";
import { VanCalenderComponent } from "./report-dash/rep-van/van-calender/van-calender.component";
import { CategoryReportComponent } from './report-dash/rep-product/category-report/category-report.component';
import { FreeGoodsReportsComponent } from "./report-dash/rep-stock/free-goods-reports/free-goods-reports.component";
import { VanStockverifyRepComponent } from "./report-dash/rep-van/van-stockverify-rep/van-stockverify-rep.component";
import { VanCustomerTransactionComponent } from "./report-dash/rep-van/van-customer-transaction/van-customer-transaction.component";
import { BranchStockComponent } from "./report-dash/rep-stock/branch-stock/branch-stock.component";
import { SaleRepBySerialNoComponent } from "./report-dash/rep-sales/sale-rep-by-serial-no/sale-rep-by-serial-no.component";
import { GoodsRecieptReportComponent } from "./report-dash/rep-purchase/goods-reciept-report/goods-reciept-report.component";
import { SupplierDetailsComponent } from "./report-dash/rep-accounts/supplier-details/supplier-details.component";
import { ProDetailedRepComponent } from "./report-dash/rep-product/pro-detailed-rep/pro-detailed-rep.component";
import { RepStockbyperiodOldComponent } from "./report-dash/rep-stock/rep-stockbyperiod-old/rep-stockbyperiod-old.component";
import { WalletTrackingRepComponent } from "./report-dash/rep-purchase/wallet-tracking-rep/wallet-tracking-rep.component";
import { CustomerWalletRepComponent } from "./report-dash/rep-sales/customer-wallet-rep/customer-wallet-rep.component";
import { VanProfitLossComponent } from "./report-dash/rep-van/van-profit-loss/van-profit-loss.component";
const routes: Routes = [
  { path: "report", component: ReportDashComponent },
  { path: "prod_detailed_rep", component: ProDetailedRepComponent },
  { path: "product_report", component: RepProductComponent },
  { path: "catagory_report", component: RepCatagoryComponent },
  { path: "productdetails_report", component: RepProductDetailsComponent },
  { path: "productsum_report", component: RepProductSummaryComponent },
  { path: "manufacture_summary", component: RepManSummaryComponent },
  { path: "stock_summary", component: RepStockSummaryComponent },
  { path: "manufacture_detail", component: ManfDetailsComponent },
  { path: "rep_stock", component: RepStockComponent },
  { path: "rep_openingstock", component: RepOpeningstockComponent },
  { path: "stocksummary_datewise", component: RepStockbyperiodComponent },
  { path: "stocksummary_datewise_old", component: RepStockbyperiodOldComponent },
  { path: "rep_purchase", component: RepPurchaseComponent },
  { path: "purchase_summary", component: PurchaseSummaryComponent },
  { path: "purchase_detail", component: PurchaseDetailsComponent },
  { path: "purchase_invoice", component: PurchaseInvoiceComponent },
  { path: "product_invoice", component: ProductInvoiceComponent },
  { path: "rep_godown", component: RepGodownComponent },
  { path: "godown_stock_summary", component: GodownStockSummaryComponent },
  { path: "godown_stock_datewise", component: GodownStockDatewiseComponent },
  { path: "godown_transfer_item", component: GodownTransferComponent },
  { path: "godown/product", component: GodownProductComponent },
  { path: "godown/transaction", component: GodownTransactionComponent },
  { path: "rep_van", component: RepVanComponent },
  { path: "van_transfer_item", component: VanTransferItemComponent },
  { path: "van_transfer_invoice", component: VanTransferInvoiceComponent },
  { path: "van_transfer_datewise", component: VanTransferDatewiseComponent },
  { path: "batch_stocksummary_datewise", component: RepBatchstockbyperiodComponent },
  {
    path: "purchase_return_summary",
    component: PurchaseReturnSummaryComponent,
  },
  {
    path: "purchase_return_product",
    component: PurchaseReturnProductComponent,
  },
  { path: "rep_production", component: ProductionDashComponent },
  { path: "production_summary", component: ProductionSummComponent },
  { path: "production_details", component: ProductionDetailsComponent },
  { path: "production_incredients", component: ProductionIncredientsComponent },
  { path: "production_commission", component: ProductionCommComponent },
  { path: "production_product", component: ProductionProductComponent },
  { path: "purchase-order-report", component: PurchaseOrderRepComponent },
  {
    path: "purchase_and_return_summary",
    component: PurchaseAndReturnReportSummaryComponent,
  },
  { path: "stock_expiry_summary", component: RepStockExpiryComponent },
  { path: "sales_summary", component: SalesSammaryComponent },
  { path: "sales_summary/:custid/:ptype/:d1/:d2", component: SalesSammaryComponent },
  { path: "rep-sales", component: RepSalesComponent },
  { path: "rep_test", component: TestComponent },
  { path: "rep_sales_prod_inv", component: SaleProdInvoiceComponent },
  { path: "sales_invoice", component: PrintSalesInvoiceComponent },
  { path: "sales_prod_rep", component: SalesProdRepComponent },
  { path: "sales_quotation_summary", component: SalesQuoatationSummaryComponent },
  { path: "ledger_detail_rep", component: LedgerDetailComponent },
  { path: 'ledger_detail_rep/:ledger_sel_id', component: LedgerDetailComponent},
  { path: 'ledger_detail_rep/:ledger_sel_id/:ptype', component: LedgerDetailComponent},
  { path: 'ledger_detail_rep/:ledger_sel_id/:ptype/:d1', component: LedgerDetailComponent},
  { path: 'ledger_detail_rep/:ledger_sel_id/:ptype/:d1/:d2', component: LedgerDetailComponent},

  { path: "company_report", component: ReportCompanyComponent },
  { path: "day_book", component: AccDaybookComponent },
  { path: "daily_summary", component: DailySummaryComponent },
  { path: "credit-note-report", component: CreditNoteRepComponent },
  { path: "credit-note-report/:custid/:ptype/:d1/:d2", component: CreditNoteRepComponent },
  { path: "debit-note-report", component: DebitNoteRptComponent },
  { path: "supp-aging_report", component: SupplierAgingRptComponent },
  { path: "customer_movment_rpt", component: CustMovmentRptComponent },
  { path: "reciept_summary", component: RecieptSummaryComponent },
  { path: "reciept_summary/:custid/:ptype/:d1/:d2", component: RecieptSummaryComponent },
  { path: "customer_details", component: CustomerDetailsComponent },
  { path: "vat-inv-rep", component: InputVatSummaryComponent },
  { path: "inp-expense-vat-rep", component: InputExpenseVatSummaryComponent },
  { path: "stock_unit_report", component: StockUnitReportComponent },

  { path: "vat-day-rep", component: DailyVatSummaryComponent },
  { path: "vat-rep", component: RepVatInvoiceComponent },
  { path: "vat-out-rep", component: OutputVatSummaryComponent },
  { path: "branchtran-rep", component: RepBranchTransferComponent },

  { path: "transfer_rep", component: TransferComponent },
  { path: "receipt_rep", component: ReceiptComponent },
  { path: "sales_return_summary", component: SalesVoidSummaryComponent },
  { path: "sales_return_summary/:custid/:ptype/:d1/:d2", component: SalesVoidSummaryComponent },
  { path: "ledger_list", component: LedgerListComponent },

  { path: "acc_grp_report", component: RepGrpSummaryComponent },

  { path: "accgrp_rep", component: AccGrpSummaryComponent },
  { path: "trial-balance", component: TrialBalanceComponent },

  { path: "op-balance-summary", component: OpBalanceSummaryComponent },

  { path: "transfer-summary", component: SummaryComponent },
  { path: "receipt-summary", component: ReceiptsummaryComponent },
  { path: "bstock-summary", component: BranchWiseComponent },
  {path:"branch-stock",component:BranchStockComponent},
  { path: "invoice-due", component: InvoiceDueComponent },
  { path: "invoice-due/:id/:aging", component: InvoiceDueComponent },
  { path: "batch-wise-stock-report", component: BatchWiseComponent },
  {
    path: "verification-of-balances",
    component: VerificationOfBalanceComponent,
  },
  { path: "damage-summary", component: RepDamageSummaryComponent },
  { path: "profit-and-loss", component: ProfitAndLossComponent },
  { path: "sales-with-return", component: SalesandreturnComponent },
  { path: "purch-edit-log", component: EditlogComponent },
  { path: "cust-aging", component: CustomeragingComponent },
  { path: "balance-sheet", component: BalanceSheetComponent },
  { path: "returned-product", component: ReturnedproductsComponent },
  { path: "customer-based", component: CustomersalesComponent },
  { path: "auditor-dash", component: RepAuditorComponent },
  {
    path: "auditor/purchase-cash-credit",
    component: CashCreditPurchaseComponent,
  },
  { path: "auditor/sales-cash-credit", component: SalesCashCreditComponent },
  { path: "auditor/transfer-to-branch", component: BranchTransferComponent },
  { path: "auditor/expenses-report", component: ExpensesReportComponent },
  { path: "auditor/banks", component: BanksComponent },
  { path: "auditor/employee", component: EmployeeComponent },
  { path: "vat-daily-summary", component: VatDailySummaryComponent },
  { path: "auditor/sale-detail", component: SaleDetailRepComponent },
  { path: "missing-stock-report", component: RepMissingSummaryComponent },
  { path: "excess-stock-report", component: RepExcessSummaryComponent },
  { path: "low-stock-alert", component: RepLowStockAlertComponent },
  { path: "excess-stock-alert", component: RepExcessStockAlertComponent },
  { path: "branch-tran-inv-based", component: RepBranchTranInvBasedComponent },
  { path: "branch-reci-inv-based", component: RepBranchReciInvBasedComponent },
  { path: "stock-verify-summary", component: RepStockVerifySummaryComponent },
  {
    path: "purchase-detail-summary",
    component: RepPurchaseDetailSummaryComponent,
  },
  {
    path: "purchase-invoice-due-summary",
    component: PurchInvoiceDueComponent,
  },
  {
    path: "invoice-due-payment-summary",
    component: InvoiceDuePaymentSummaryComponent,
  },
  {
    path: "purchase-invoice-due-summary/:id/:aging",
    component: PurchInvoiceDueComponent,
  },
  { path: "van_stock_tracking", component: VanStockTrackingComponent },
  { path: "van_stock_tracking_unitwise", component: VanStockTrakingUnitwiseComponent },
  { path: "van-sale-report", component: VanSaleRepComponent },
  { path: "visiting-report", component: VisitingRepComponent },
  { path: "van-itemwise-tracking", component: VanItemwiseRepComponent },
  { path: "van_distributors_report", component: VanDistributorsRepComponent },
  { path: "van_profit_loss", component: VanProfitLossComponent },
  { path: "item_movement_report", component: RepItemMovmentComponent },
  { path: "item_unitwise_tracking", component: ItemUnitwiseTrackingComponent },
  { path: "free_goods_reports", component: FreeGoodsReportsComponent },
  {
    path: "stock_vrfctn_inv_based",
    component: RepStockVerificationInvBasedComponent,
  },
  { path: "inv_due_recpt_summary", component: InvDueRecSummaryComponent },
  { path: "cash_bank_book", component: RepCashBankBookComponent },
  { path: "godown/godown-details", component: GodownDetailsComponent },
  { path: "customers-report", component: RepCustomersComponent },
  { path: "cash-counter-report", component: CashCounterReportComponent },
  { path: "top-customers-report", component: RepTopCustomersComponent },
  { path: "top-supplliers-report", component: RepTopSuppliersComponent },
  { path: "top-products-report", component: RepTopProductsComponent },
  { path: "van_detailed_report", component: VanDetailedRepComponent },
  { path: "van_topsheet_report", component: VanTopsheetRepComponent },
  { path: "van_missing_stock_report", component: VanMissingStockComponent },
  { path: "branch-detailed-rep", component: BranchDetailedRepComponent },
  { path: "item-tracking-rep", component: ItemTrackingRepComponent },
  { path: "product_commision_rpt", component: RepProductCommisionComponent },
  { path: "ext-transfer-rep", component: RepExtTransferComponent },
  { path: "ext_transfer_inv_based", component: ExtTransferInvBasedComponent },
  { path: "ext_transfer_det", component: ExtTransferDetComponent },
  { path: "ext_receipt_inv_based", component: ExtReceiptInvBasedComponent },
  { path: "ext_receipt_det", component: ExtReceiptDetComponent },
  { path: "rep-expense-tax-payment-summary", component: ExpenseTaxPaymentSummaryComponent },
  { path: "rep-expense-tax-payment-summary/:custid/:ptype/:d1/:d2", component: ExpenseTaxPaymentSummaryComponent },
  { path: "item-movement-details", component: ItemMovementDetailsComponent },
  { path: "sales-agent-report", component: SalesAgentReportComponent },
  { path: "delivery-challan-report", component: DeliveryChallanRepComponent },
  { path: "delivery-challan-itemwise", component: DeliveryChallanItemwiseComponent },
  { path: "sales-order-report", component: SalesOrderRepComponent },
  { path: "product_list", component: UnitListComponent },
  { path: "van-excess-stock-report", component: VanExcessStockRepComponent },
  { path: "cost-category-report", component: CostCategoryComponent },
  { path: "daily_operation", component: RepCashBookComponent },
  { path: "warranty-stock-report", component: RepWarrantySummaryComponent },
  { path: "van-calendar", component: VanCalenderComponent },
  { path: "van-stock-verify-report", component: VanStockverifyRepComponent },
  { path: "van-customer-transaction", component: VanCustomerTransactionComponent },
  { path: "product-category-report", component: CategoryReportComponent },
  {path:"sale_rep_by_serialno",component:SaleRepBySerialNoComponent},
  {path:"goods_reciept_report",component:GoodsRecieptReportComponent},
  { path: "supplier_details", component: SupplierDetailsComponent },
  {path:"wallet_tracking_rep",component:WalletTrackingRepComponent},
  {path:"customer_wallet_rep",component:CustomerWalletRepComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportRoutingModule {}
