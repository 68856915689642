import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.css']
})
export class SupplierDetailsComponent implements OnInit {
  supplierDetails: any;
  ledgers: any;
  bal: number;
  tempImgUrl: any;
  logoImgUrl: any = '';
  voucherDetails: any;
  previewData: any;
  purchaseItems: any;
  recPriviewLoader: boolean;
  printPreview: any;
  payPriviewLoader: boolean;
  salesPriviewLoader: boolean;
  resultobj: any;
  showdata: boolean;
  is_prew_description: any;
  purchData: any;
  purchDataItems: any;
  gd_name: string;
  purchDataRetAmount: number;
  saleprivewLoadingImg: boolean;
  salesRetPreview: any;
  qr_inv: any;
  previewDataitems: any;
  stocktr_amount: any;
  transferdate: any;
  datepipe: any;
  received_date: Date;
  total_supplier: any;
  total_cr_amount: any;
  total_dr_amount: any;
  fileName="vouchers.xlsx";
  fileName2="Supplier_statement.xlsx";
  fileName1="due_statement.xlsx";
  search_txt ="";
  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];
  period_type: string;
  pageLoadingImg: boolean;
  cur_lang: string;
  exportLoader: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address: any;
  country_dec: number=2;
  supplrDetails: any;
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  dueDetails: any;
  due_loading: boolean;
  constructor(private apiService: ApiService, private coreService: CoreService,
    private translate: TranslateService, private currencyPipe: CurrencyPipe, private datePipe: DatePipe,private excelService: ExcelService) { }



  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      //  this.langChange();
     });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.country_dec=this.coreService.setDecimalLength();
    const searchval = new FormData();
    this.getTemplete();

    this.apiService.listSupplierDet(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
     // console.log(res.data);
       this.ledgers =res.data;
       console.log(' this.ledgers', this.ledgers);
       
      this.total_supplier = res.total_supplier;
      this.total_cr_amount = res.total_cr_amount;
       this.total_dr_amount = res.total_dr_amount;
      // this.dayList = res.data.cashInHand;
      // this.acc = res.data.bankAccount;
   
    });


    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
  }


  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }


  searchInp(e) {
    let searchval = new FormData();
    searchval.append("search", e);
    if(this.translate.currentLang =='Arabic'){
      searchval.append("with-alias", "1");
    }
   
   // console.log(this.search_txt);
    this.pageLoadingImg = true;
    this.apiService.searchSupplierDet(searchval, 1).subscribe((res) => {
      this.ledgers =res.data;
      this.pageLoadingImg = false;
    });
    
  }


  filterSearch(formdata: { value: any; }) {
    this.search_txt="";
    this.pageLoadingImg = true;

    this.apiService.listSupplierDet(formdata.value, 1).subscribe((res: any) => {
      this.ledgers =res.data;
      this.total_supplier = res.total_supplier;
      this.total_cr_amount = res.total_cr_amount;
      this.total_dr_amount = res.total_dr_amount;
      this.pageLoadingImg = false;

    });
  }

  supplierdetail(id,formdata: { value: any; }) {
    formdata.value.ledger_id=id;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.supplierDet(formdata.value).subscribe((res) => {
   
     this.supplrDetails = res.data;   
    });
  }
  vouchersdetail(id,formdata: { value: any; }) {


    formdata.value.ledger_id=id;
    formdata.value.ledger_sel_id=id;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    this.apiService.VoucherSupplierDet(formdata.value).subscribe((res) => {
    //   this.payPriviewLoader = false;
     this.voucherDetails = res.data;   
     this.bal = 0 - res.data.op_balance;
          if (this.voucherDetails.vouchers.length > 0) {
            for (let i = 0; i < this.voucherDetails.vouchers.length; i++) {
              this.voucherDetails.vouchers[i].balance = this.bal = this.bal + (this.voucherDetails.vouchers[i].vch_in - this.voucherDetails.vouchers[i].vch_out);
            }
          }
    });
   
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportexcel2(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group2');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName2);
    this.exportLoader = false;

  }


  exportEXCL2() {
    this.exportLoader = true;
    
       setTimeout(() => {
        this.exportexcel2();
      }, 3000);
   

   
  }



  exportEXCL(id,supplier_summary) {
    this.exportLoader = true;
    this.apiService.VouchersDet({ ledger_id: id , ledger_sel_id:id,hide_amnt:supplier_summary.value.hide_amnt,period_type:supplier_summary.value.period_type,}).subscribe((res) => {
    //   this.payPriviewLoader = false;
     this.voucherDetails = res.data;   
    // console.log(this.voucherDetails);

     this.bal = 0 - res.data.op_balance;
          if (this.voucherDetails.vouchers.length > 0) {
            for (let i = 0; i < this.voucherDetails.vouchers.length; i++) {
              this.voucherDetails.vouchers[i].balance = this.bal = this.bal + (this.voucherDetails.vouchers[i].vch_in - this.voucherDetails.vouchers[i].vch_out);
            }
          }

          setTimeout(() => {
            this.exportexcel();
          }, 3000);
    });


   
  }

  exportEXCL1(id) {
    this.exportLoader = true;
    



    this.apiService.getSupplierInvoice({ sup_ledger_id: id}).subscribe((res) => {
    //   this.payPriviewLoader = false;
     this.dueDetails = res.data;   
    // console.log(this.voucherDetails);

   

          setTimeout(() => {
            this.exportexcel1();
          }, 3000);
    });


   
  }
  exportexcel1(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group1');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName1);
    this.exportLoader = false;

  }

  public dayBetweenDates(sdate, edate = new Date()) {
    // Get 1 day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Convert both dates to milliseconds
    const sdateMs = new Date(sdate).getTime();
    const edateMs = new Date(edate).getTime();
    // Calculate the difference in milliseconds
    const differenceMs = edateMs - sdateMs;
    // Convert back to days and return
    return Math.round(differenceMs / oneDay);
  }

  generatePdf() {
  
    const heads = [
      this.translate.instant('Purchase.supplier'),
      this.translate.instant('Common.Balance'),
      this.translate.instant('Common.Due_Invoices')
    ];

      const lblXPos = 10;
      const headerHeight = 15;
      const valueXPos = 55;
      const data = [];

      this.ledgers.forEach(item=>{
        data.push([
          (this.translate.currentLang == 'Arabic' && item.ledgerAlias_AR) ? item.ledgerAlias_AR : item.ledgerName,
          (item.bal_without_sign).toFixed(this.country_dec) + ' ' + item.bal_sign,
          item.due_invoice
      ]);
      
        
      })
  
      let doc = this.excelService.getPdfObj();

      var addressLines = this.branch_address.split('\r\n');
      var addressText = addressLines.join(', ');

      doc.setFont('Amiri');
      doc.setFontSize(12);
      doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

      doc.setFont('Amiri');
      doc.setFontSize(9);
      doc.text(addressText, 71, headerHeight + 2);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + `${this.translate.instant('Common.suppliers')}`,  valueXPos, headerHeight + 10);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('HOME.total_supplier')}`, lblXPos, headerHeight + 15);
      doc.setFontSize(10);
      doc.text(':  ' + this.total_supplier,  valueXPos, headerHeight + 15);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.total_dr_amount')}`, lblXPos, headerHeight + 20);
      doc.setFontSize(10);
      doc.text(':  ' + (this.total_dr_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 20);

      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Table.total_cr_amount')}`, lblXPos, headerHeight + 25);
      doc.setFontSize(10);
      doc.text(':  ' + (this.total_cr_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 25);
      
      doc = this.excelService.addTableToPdf(doc, headerHeight + 30, lblXPos, heads, data, null);
  
     
      doc.save(`Supplier.pdf`);


    
  }


  viewContra(id) {
    this.recPriviewLoader = true;

    this.apiService.getContra({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;

      this.printPreview = res;

      // console.log(this.printPreview.rec_ttl_amount);

    });

  }
  viewJournal(id) {
    this.recPriviewLoader = true;

    this.apiService.getJournal({ ref_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;

      this.printPreview = res;
      console.log('this.printPreview',this.printPreview);
      


    });

  }
  viewPayment(id) {
    this.payPriviewLoader = true;

    this.apiService.getPayment({ rec_no: id }).subscribe((res) => {
      this.payPriviewLoader = false;

      this.printPreview = res;
console.log(' this.printPreview', this.printPreview);


    });
  }

  viewDueInvoicePayment(id) {
    this.recPriviewLoader = true;
    this.apiService.getInvoiceDuePayment({ pay_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;
      this.printPreview = res;
    });
  }

  viewReceipt(id) {
    this.recPriviewLoader = true;
    this.apiService.getReceipt({ rec_no: id }).subscribe((res) => {
      this.recPriviewLoader = false;
      this.printPreview = res;
    });
  }
  
  sale_search(search) {
    this.salesPriviewLoader = true;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
        console.log('this.saleData',this.saleData);
        
      }


    });
  }

  previewPurchase(id) {
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purch_id', id);
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.previewData = res.data;
      this.is_prew_description = this.previewData['items'].filter(x => x.purchsub_item_desc);
      // setTimeout(function () { $("#previewButton").click(); }, 1000);

    });
  }


  duedetail(id) {
   

    this.dueDetails=[];
    this.due_loading=true;
     this.apiService.getSupplierInvoice({ sup_ledger_id: id }).subscribe((res) => {
      this.due_loading=false;
      this.dueDetails = res.data;   
     // console.log(this.dueDetails);
     });
    
   }
   public checkDueExp(dueDate) {
    const duedateMs = new Date(dueDate).getTime();
    const crrdateMs = new Date().getTime();
    const differenceMs = duedateMs - crrdateMs;
    if (differenceMs < 0) {
      return true;
    } else {
      return false;
    }
  }



  getPurchRetPreview(id: any) {
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purchret_id', id);
    this.apiService.getpurchaseRetDetails(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.purchData = res['data'][0];
      this.purchDataItems = res['data'][0]['items']; 
      if(!res['data'][0].purchret_frieght)
      res['data'][0].purchret_frieght = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0;
      if(!res['data'][0].purchret_discount)
      res['data'][0].purchret_discount = 0; 
      
      this.gd_name = 'Shop';

      this.purchDataRetAmount = Number(res['data'][0].purchret_amount);
      this.purchData.purchret_amount = Number(res['data'][0].purchret_amount)-Number(res['data'][0].purchret_tax)
      +Number(res['data'][0].purchret_discount);
    });

  }

  getSalesRetPreview(prdn_id) {
    this.saleprivewLoadingImg = true;
    let searchval = new FormData();
    searchval.append("ref_no", prdn_id);
    this.apiService.getsalesReturnPrev(searchval).subscribe((res) => {
      this.saleprivewLoadingImg = false;
      this.salesRetPreview = res;
      console.log('this.salesRetPreview',this.salesRetPreview);
      
      this.salesRetPreview.total_vat = Number(res.total_vat);
      this.salesRetPreview.total_amt = Number(res.total_amt);

      this.qr_inv = this.salesRetPreview.qr_link;

      this.apiService.getSettings().subscribe((res) => {
        if (res.data.prnt_header_type == 2) {
          this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
        } else {
          this.tempImgUrl = '';
        }
  
      });

    });

  }


  getStockTranPreview(prdn_id) {

    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.transferPreview(searchval).subscribe((res) => {
      this.previewData = res;
      // this.branch_from = res.from_branch;
      this.previewDataitems = res.items;
      this.stocktr_amount = res.stocktr_amount;
      this.transferdate = this.datepipe.transform(res.stocktr_date, 'dd/MM/yyyy');
      var to = this.transferdate.split("/");
      this.transferdate = new Date(to[2], to[1] - 1, to[0]);
    });

  }
  getStockRecPreview(prdn_id) {
    this.previewData = [];
    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.transferPreview(searchval).subscribe((res) => {
      this.previewData = res;
      this.received_date = new Date(res.stocktr_date);
    });

  }


}
