import { Component, OnInit } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sale-prod-invoice',
  templateUrl: './sale-prod-invoice.component.html',
  styleUrls: ['./sale-prod-invoice.component.css']
})
export class SaleProdInvoiceComponent implements OnInit {

  fileName = 'sale_product_invoice_summary.xlsx';
  sales_agents: any;
  searchval: any;
  pageLoadingImg: boolean;
  salesSum: any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  repDate: any;
  period_type: any = 't';
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val1: any;
  inv_val2: any;
  rate_filter: any;
  rate_val1: any;
  rate_val2: any;
  purch_qty_filter: any;
  purch_qty: any;
  purch_qty2: any;
  barcode_filter: any;
  bar_val1: any;
  bar_val2: any;
  prd_id: any;
  cat_id: any;
  added_by: any;
  products: any; catagories: any;
  users: any;
  logoImgUrl: any = '';
  tempImgUrl: any;
  payType = ['Cash', 'Credit'];
  periodTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'tm', name: 'This Month' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  report_type_filter = [
    { id: 'summary', name: 'Summary' },
    { id: 'detailed', name: 'Detailed' },
    { id: 'detailed_with_profit', name: 'Detailed With Profit' }
  ];
  detailed_with_profit_type_filter = [
    { id: 'product_wise', name: 'Product Wise' },
    { id: 'invoice_wise', name: 'Invoice Wise' },
  ];
  det_with_prft_type:string;

  total_amount: any;
  total_discount_amount: any;
  total_qty_sold: any;
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  up: any;
  vans: any;
  exportLoader: boolean;
  ExprepDate: any;
  ExpsalesSum: any;
  Exptotal_amount: any;
  Exptotal_discount_amount: any;
  Exptotal_qty_sold: any;

  godowns: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_address:any;
  report_type: any;
  rep_type: string;
  Expreport_type: any;
  taxCategories: any;
  total_item_cost: any;
  total_item_profit: any;
  enablePrft: boolean;
  errorPassword: any;
  ExpenablePrft: boolean;
  Exptotal_item_cost: any;
  Exptotal_item_profit: any;
  show = false;
  country_dec: string;
  salesPriviewLoader: boolean;
  purchaseItems: any[];
  saleData: any = {
    sales_id: null,
    sales_inv_no: null,
    error: null,
    message: null,
    data: null,
  };
  resultobj: any;
  showdata: boolean;
  savedTemplate = {
    title_text: '',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: ''
  }
  customer: any
  priceGroupList: any;
  price_grp : any = null;
  cust_id:any=null;
  storag_eqp_list = [
    { id: 'no', name: 'No' },
    { id: 'yes', name: 'Yes' },
  ];
  customer_category: any;
  cur_lang: string;
  res: any;
  SaleProdInv: any;
  heads: any[];
  typeCheck: any;
  profit_pass: any;
  detprft: boolean=false;
  errorprofit_pass: string;
  langFilter: any;
  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe, private datePipe: DatePipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.getTemplete();
    this.rep_type = 'summary';
    this.det_with_prft_type = 'product_wise';
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

    this.getGodownList();
    this.pageLoadingImg = true;
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.filter_branch_id = 1;
    let searchval = new FormData();
    searchval.append('period_type','t');
    this.apiService.salesProductInvoice(searchval, 1).subscribe((res: Sales) => {
      this.show = true;
      this.pageLoadingImg = false;
      this.salesSum = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];

      this.total_amount = res['data']['total_amount'];
      this.total_discount_amount = res['data']['total_discount_amount'];
      this.total_qty_sold = res['data']['total_qty_sold'];
      if(res.data.profit_rep){
        this.enablePrft = true;
        this.total_item_cost = res.data.profit.total_item_cost;
        this.total_item_profit = res.data.profit.total_item_profit;
      }else{
        this.enablePrft = false;
      }
      this.errorPassword = '';
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
    this.searchUser('');
    this.getAllTaxCategories();

    this.country_dec=this.coreService.setDecimalLength();
    this.apiService.getAllPriceGroup().subscribe((res) => {
      this.priceGroupList = res.data;
    });
    this.getAllCustomerCategory();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    
  }
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('price_group', this.price_grp ? this.price_grp : '');
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });
  
  }
  changePriceGroup(){
      this.customer = '';
      this.cust_id = null;
  }
  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','Common.summary','Common.detailed','Common.detailed_with_profit','Common.this_month']).subscribe((res: string) => {    
     
      
      this.periodTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'tm', name: res['Common.this_month'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.report_type_filter = [
        { id: 'summary', name: res['Common.summary'] },
        { id: 'detailed', name: res['Common.detailed'] },
        { id: 'detailed_with_profit', name: res['Common.detailed_with_profit'] }
      ];
    });

  }

  ngAfterContentInit() {

    this.searchVans('');

  }
  changeRepType(){
    this.show = false;
  }

  getAllTaxCategories(){
    this.apiService.getAllTaxCategory().subscribe((resp) => {

      this.taxCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
    });
  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }

  ngOnDestroy() {
    $('.close').click();

  }

  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
    });
  }


  pageNext(formdata: { value: any; }, pageno: any) {

    this.pageLoadingImg = true;
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    if (formdata.value.price_grp === null) {
      formdata.value.price_grp = '';
    }
    if (formdata.value.cust_id === null) {
      formdata.value.cust_id = '';
    }
    if (formdata.value.storag_eqp === null) {
      formdata.value.storag_eqp = '';
    }
    
    this.apiService.salesProductInvoice(formdata.value, pageno).subscribe((res: Sales) => {
      this.show = true;
      this.pageLoadingImg = false;
      this.repDate = res.data.Date;
      this.salesSum = res['data']['data'];
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.report_type = res.data.rep_type;

      this.total_amount = res['data']['total_amount'];
      this.total_discount_amount = res['data']['total_discount_amount'];
      this.total_qty_sold = res['data']['total_qty_sold'];
      if(res.data.profit_rep || res.data.det_profit_rep){
        if(res.data.profit_rep){
          this.enablePrft = true;
        }
        this.total_item_cost = res.data.profit.total_item_cost;
        this.total_item_profit = res.data.profit.total_item_profit;
      }else{
        this.enablePrft = false;
      }
      this.errorPassword = '';

      // detaild with profit passcheck
      if(formdata.value.enbPrft){
        if(this.profit_pass &&this.profit_pass =='dev.prft2020'){
          this.detprft=true;
        }else{
          this.detprft=false;
          this.errorprofit_pass='Inavlid Password';
        }
      }
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      if(!res.data.msg){
        $('.close').trigger('click');
      }else{
        this.errorPassword = res.data.msg;
      }
    });

  }


  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
  searchval.append('alias_search', '1');
}
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }



  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchUser(search: string) {
    let searchval = new FormData();
    searchval.append("usr_name", search);
    this.apiService.getUserByName(searchval).subscribe((res) => {
      this.users = res['data'];
    });
  }

  searchVans(search: string) {
    const searchval = new FormData();
    searchval.append('van_name', search);
    this.apiService.searchVans(searchval).subscribe((res) => {
      this.vans = res['data'];
      this.vans.push({ "van_id": 'All', 'van_name': 'All' });

    });

  }

  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.sales_agents = res['data'];

    });

  }

  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.sales_agents = res['data'];
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

    // pdf download
    generatePdf(Expformdata: { value: any; }) {

      this.exportLoader = true;

      Expformdata.value.export = 'export';

      Expformdata.value.pdf = 'pdf';

      this.apiService.salesProductInvoice(Expformdata.value, 1).subscribe((res: Sales) => {
        this.show = true;
        this.ExprepDate = res.data.Date;
        this.ExpsalesSum = res['data']['data'];
        this.Expreport_type = res.data.rep_type;
        this.typeCheck =  res.data.det_profit_rep;
        
        this.SaleProdInv = res.data;
        console.log(this.SaleProdInv);
        
        this.Exptotal_amount = res['data']['total_amount'];
        this.Exptotal_discount_amount = res['data']['total_discount_amount'];
        this.Exptotal_qty_sold = res['data']['total_qty_sold'];
  
        if(res.data.profit_rep || res.data.det_profit_rep){
          if(res.data.profit_rep){
            this.ExpenablePrft = true;
          }
          this.Exptotal_item_cost = res.data.profit.total_item_cost;
          this.Exptotal_item_profit = res.data.profit.total_item_profit;
        }else{
          this.ExpenablePrft = false;
        }

        if(this.enablePrft){
          this.heads = [
            this.translate.instant('Common.prd_name'),
            this.translate.instant(' '),
            this.translate.instant('HOME.Invoice_no'),
            this.translate.instant('Common.date'),
            this.translate.instant('Table.item_cost'),
            this.translate.instant('Table.item_profit'),
            this.translate.instant('Table.rate'),
            this.translate.instant('Table.qty'),
            this.translate.instant('Table.amt'),
            this.translate.instant('Sales.Item_Disc'),
            this.translate.instant('transaction.Branch_Inv_No'),
            this.translate.instant('Sales.Van_Inv_No')
          ];

        }

        else if(this.Expreport_type == 'detailed'){
          this.heads = [
            this.translate.instant('Common.date'),
            this.translate.instant('transaction.Branch_Inv_No'),
            this.translate.instant('Sales.Name_Of_The_Customer'),
            this.translate.instant('Common.Vat_Reg_No'),
            this.translate.instant('Table.Item_Price'),
            this.translate.instant('Purchase.vat'),
            this.translate.instant('Table.item_total'),
            this.translate.instant('Table.item_name'),
            this.translate.instant('Table.qty')
          ];
        }

        else if(this.typeCheck){
          this.heads = [
            this.translate.instant('Common.prd_name'),
            this.translate.instant('Purchase.Catgory'),
            this.translate.instant('HOME.SUB CATAGORY'),
            this.translate.instant('Table.qty'),
            this.translate.instant('Common.avg_amt')
           
          ];

          if(this.detprft) {
            this.heads.splice(3,0, this.translate.instant('Table.item_cost')),
            this.heads.splice(4,0, this.translate.instant('Common.avg_rate')),
            this.heads.splice(7,0, this.translate.instant('Table.item_profit'))
          }
        }

        else{

          this.heads = [
            this.translate.instant('Common.prd_name'),
            this.translate.instant(' '),
            this.translate.instant('HOME.Invoice_no'),
            this.translate.instant('Common.date'),
            this.translate.instant('Table.rate'),
            this.translate.instant('Table.qty'),
            this.translate.instant('Table.amt'),
            this.translate.instant('Sales.Item_Disc'),
            this.translate.instant('transaction.Branch_Inv_No'),
            this.translate.instant('Sales.Van_Inv_No')
          ];

        }

        
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];

        this.SaleProdInv.data.forEach(item=>{
          if(this.Expreport_type != 'detailed'){
          if(this.enablePrft){
            data.push([this.translate.currentLang === "Arabic" ? item.prd_alias : item.prd_name, '', '', '', '', '', '', '', '', '', '']);
          } else if(this.rep_type == 'summary'){
            data.push([this.translate.currentLang === "Arabic" ? item.prd_alias : item.prd_name, '', '', '', '', '', '', '', '']);          }
        }

          if(this.Expreport_type == 'detailed'){

            if(this.translate.currentLang == 'Arabic'){
              if(item.sales.ledger_alias){
                this.langFilter =  item.sales.ledger_alias 
              }
              else if(item.sales.ledger_name_ar){
                this.langFilter =  item.sales.ledger_name_ar 
              }
              else if(item.sales.ledger_name){
                this.langFilter =  item.sales.ledger_name  
              }
            } else if(this.translate.currentLang == 'English'){
              this.langFilter =  item.sales.sales_cust_name
            }
            
            data.push([
            this.datePipe.transform(item.salesub_date, 'dd/MM/yyyy'),
            item.branch_inv_no,
            this.langFilter,
            item.sales.sales_cust_tin,
            (item.sale_amount).toFixed(this.country_dec),
            (item.sale_tax).toFixed(this.country_dec),
            (item.sale_with_tax).toFixed(this.country_dec),
            item.prd_name,
            item.salesub_qty + '(' + item.drvd_qty + '-' + item.unit_code + ')',
          ])
          }

          if(this.typeCheck){
            const arr = [
              item.items[0].prd_name,
              item.items[0].cat_name,
              item.items[0].subcat_name,         
              item.items[0].salesub_qty,
              (item.items[0].avg_rate).toFixed(this.country_dec), 
            ]

            if(this.detprft) {
              arr.splice(3,0, (item.items[0].item_cost).toFixed(this.country_dec)),
              arr.splice(4,0, (item.items[0].salesub_rate).toFixed(this.country_dec)),
              arr.splice(7,0, (item.items[0].item_profit).toFixed(this.country_dec))
            }
            data.push(arr);
          }

          if(this.Expreport_type != 'detailed'){

            item.items.forEach(item1=>{
              if(this.enablePrft){
                data.push(['',
                item1.prd_barcode,
                item1.inv_no,
                this.datePipe.transform(item1.salesub_date, 'dd/MM/yyyy'),
                (item1.item_cost).toFixed(this.country_dec),
                (item1.item_profit).toFixed(this.country_dec),
                (item1.salesub_rate).toFixed(this.country_dec),
                item1.salesub_qty,
                (item1.sale_amount).toFixed(this.country_dec),
                (item1.salesub_discount).toFixed(this.country_dec),
                item1.branch_inv_no,
                item1.van_inv_no])
              }

              else if(this.rep_type == 'summary') {
                data.push(['',
                item1.prd_barcode,
                item1.inv_no,
                this.datePipe.transform(item1.salesub_date, 'dd/MM/yyyy'),
                (item1.salesub_rate).toFixed(this.country_dec),
                item1.salesub_qty,
                (item1.sale_amount).toFixed(this.country_dec),
                (item1.salesub_discount).toFixed(this.country_dec),
                item1.branch_inv_no,
                item.van_inv_no])
              }
            })
          }
          

        })
    
        let doc = this.excelService.getPdfObj();

        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');

        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);

        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Sales.Sold_Products_Summary_Invoice_Based')}`,  valueXPos, headerHeight + 15);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Sales.Total_Sold_Quantity')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.Exptotal_qty_sold ,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Sales.Total_Sale_Amount')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + (res.data.total_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.total_discount_amount')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + (res.data.total_discount_amount).toFixed(this.country_dec),  valueXPos, headerHeight + 30);

        console.log("this.typeCheck" + this.typeCheck);

        if(this.enablePrft || (this.typeCheck &&this.detprft)){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Table.toatl_item_cost')}`, lblXPos, headerHeight + 35);
          doc.setFontSize(10);
          doc.text(':  ' + (res.data.profit.total_item_cost).toFixed(this.country_dec),  valueXPos, headerHeight + 35);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Table.toatl_item_profit')}`, lblXPos, headerHeight + 40);
          doc.setFontSize(10);
          doc.text(':  ' + (res.data.profit.total_item_profit).toFixed(this.country_dec),  valueXPos, headerHeight + 40);
        }
  
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 45, lblXPos, this.heads, data, null);
    
       
        doc.save(`Sold Products Summary(Invoice Based).pdf`);

        this.exportLoader = false;
      });

      
    }


  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  exportEXCL(Expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    Expformdata.value.export = 'export';

    if (Expformdata.value.period_type === 'c') {
      Expformdata.value.date1 = (Expformdata.value.date1) ? this.apiService.formatDate(Expformdata.value.date1) : Expformdata.value.date1;
      Expformdata.value.date2 = (Expformdata.value.date2) ? this.apiService.formatDate(Expformdata.value.date2) : Expformdata.value.date2;
    }
    this.apiService.salesProductInvoice(Expformdata.value, 1).subscribe((res: Sales) => {
      this.show = true;
      this.ExprepDate = res.data.Date;
      this.ExpsalesSum = res['data']['data'];
      this.Expreport_type = res.data.rep_type;

      this.Exptotal_amount = res['data']['total_amount'];
      this.Exptotal_discount_amount = res['data']['total_discount_amount'];
      this.Exptotal_qty_sold = res['data']['total_qty_sold'];

      if(res.data.profit_rep || res.data.det_profit_rep){
        if(res.data.profit_rep){
          this.ExpenablePrft = true;
        }
        this.Exptotal_item_cost = res.data.profit.total_item_cost;
        this.Exptotal_item_profit = res.data.profit.total_item_profit;
      }else{
        this.ExpenablePrft = false;
      }


      setTimeout(() => {
        this.exportexcel();
      }, 3000);


      Expformdata.value.export = '';
    });



  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }

      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }



  sale_search(search) {
    this.salesPriviewLoader = true;
    this.pageLoadingImg = true;
    const searchval = new FormData();
    searchval.append('sales_inv_no', search);
    this.apiService.getSalesInv(searchval).subscribe((res) => {
      this.pageLoadingImg = false;
      this.salesPriviewLoader = false;
      if (res.error != null) {
        this.resultobj = res.error;
        this.purchaseItems = [];
        this.saleData = {
          sales_id: null,
          sales_inv_no: null,
          error: null,
          message: null,
          data: null,

        };
        this.resultobj = this.coreService.getValidation(this.resultobj);
      } else {
        this.showdata = true;

        this.resultobj = {};
        this.purchaseItems = res.data;
        this.saleData = res.data;
      }


    });
  }

  getAllCustomerCategory()
  {
    this.apiService.listCustCategory().subscribe((res) => {
      this.customer_category = res['data'];
    });
  }

  

}
