import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-cash-credit-purchase',
  templateUrl: './cash-credit-purchase.component.html',
  styleUrls: ['./cash-credit-purchase.component.css']
})
export class CashCreditPurchaseComponent implements OnInit {
  fileName = 'purchase.xlsx';

  pageLoadingImg: boolean;
  curpage: any;
  lastpage: any;
  pgend: boolean;
  pgstart: boolean;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  branch_tin: any;
  branch_address: any;
  summary: any;
  payment_type: any;
  repDate: any;
  payType: any;
  userType: any;
  branch_all: any;
  Expsummary: any;
  ExprepDate: any;
  ExppayType: any;
  exportLoader: boolean;
  period_type: string;
  branch: any;
  Expbranch: any;
  Alldet: any;
  cmp_type: any;
  filter_branch_id: any;
  ExpAlldet: any;
  cmpExist: boolean;
  country_dec: string;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  filter_cmptype = [
    { id: '1', name: 'Establishment' },
    { id: '2', name: 'Company' },

  ];
  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];
  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.cmp_type = '1';
    this.cmpExist = true;

    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.payType = '1';
    this.period_type = 't';
    let searchval = new FormData();
    searchval.append("payment_type", '1');
    searchval.append("period_type", 't');
    searchval.append("cmp_type",this.cmp_type);

    this.apiService.cashCreditPurchase(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.summary = res['data']['data']['data'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data'].Date;
      this.branch = res['data'].branch;
      this.Alldet = res['data'].Summary;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // this.slnum = 0;

    });

    this.changeCmpType(1);
    this.payment_type = '1';


  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.credit','Common.cash']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.filter_paytype = [
        { id: '1', name: res['HOME.credit'] },
        { id: '2', name: res['Common.cash'] },
    
      ];
    });

  }

  ngOnDestroy() {
    $('.close').click();

  }

  changeCmpType(cmptype) {
    let searchval = new FormData();
    searchval.append("cmp_type", cmptype);
    this.filter_branch_id = [];

    this.apiService.getBranchbyType(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      this.checkBranch();
      
      // if (this.userType == 'ADMIN') {
      //   this.branch_all.push({ 'branch_id': '', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      // }
    });
  }

  checkBranch(){
    if(this.filter_branch_id.length > 0)
    {
      this.cmpExist = true;
    }else{
      this.cmpExist = false;
    }
  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      // if (this.userType == 'ADMIN') {
      //   this.branch_all.push({ 'branch_id': '', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      // }
    });
  }


  pageNext(formdata: { value: any; }, pageno: any) {

    $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';

    this.apiService.cashCreditPurchase(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.summary = res['data']['data']['data'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data'].Date;
      this.payType = res['data'].payType;
      this.branch = res['data'].branch;
      this.Alldet = res['data'].Summary;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }




  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }


  export(expformdata: { value: any; }) {
    this.exportLoader = true;

    // debugger;
    expformdata.value.export = 'export';
    this.apiService.cashCreditPurchase(expformdata.value, 1).subscribe((res: any) => {
      this.Expsummary = res['data']['data']['data'];

      this.ExprepDate = res['data'].Date;
      this.ExppayType = res['data'].payType;
      this.Expbranch = res['data'].branch;
      this.ExpAlldet = res['data'].Summary;


      setTimeout(() => {
        this.exportexcel();
      }, 3000);



    });



  }


  // pdf download
  generatePdf(expformdata: { value: any; }) {

    this.exportLoader = true;

    // debugger;
    expformdata.value.export = 'export';
    this.apiService.cashCreditPurchase(expformdata.value, 1).subscribe((res: any) => {
      this.Expsummary = res['data']['data']['data'];

      this.ExprepDate = res['data'].Date;
      this.ExppayType = res['data'].payType;
      this.Expbranch = res['data'].branch;
      this.ExpAlldet = res['data'].Summary;


      const heads = [
        this.translate.instant('Table.date'),
        this.translate.instant('HOME.Invoice_no'),
        this.translate.instant('HOME.PURCHASE'),
        this.translate.instant('Purchase.vat'),
        this.translate.instant('Branch.Total with tax'),
        this.translate.instant('Purchase.supplier'),
        this.translate.instant('Branch.Supplier Vat No')
      ];
  
        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.Expsummary.forEach(item=>{
          const arr=[
            item.purch_date,
            item.purch_inv_no,
            (item.purch_amount).toFixed(this.country_dec),
            (item.purch_tax).toFixed(this.country_dec),
            (item.purch_total).toFixed(this.country_dec)
          ]
if(this.translate.currentLang == "Arabic"){ if(item.supp_alias){
  arr.splice(5,0, item.supp_alias)
}}else if(item.supp_name){ arr.splice(5,0, item.supp_name)}
         

          if(item.supp_tin){
            arr.splice(6,0, item.supp_tin)
          }

          data.push(arr)
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.ExprepDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Branch.Credit Purchase')}`,  valueXPos, headerHeight + 15);
  
        if(this.branch){

          if(this.branch.branch_tin){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.branch_reg_no')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' + this.branch.branch_tin,  valueXPos, headerHeight + 20);
          } else {
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('HOME.branch_reg_no')}`, lblXPos, headerHeight + 20);
            doc.setFontSize(10);
            doc.text(':  ' ,  valueXPos, headerHeight + 20);
          }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Common.total_purchases')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + (this.Alldet.total_purch).toFixed(this.country_dec),  valueXPos, headerHeight + 25);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Branch.Activity')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + ' ',  valueXPos, headerHeight + 30);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Branch.Mobile No')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + this.branch.branch_mob,  valueXPos, headerHeight + 35);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Branch.Charge Person')}`, lblXPos, headerHeight + 40);
        doc.setFontSize(10);
        doc.text(':  ' + ' ',  valueXPos, headerHeight + 40);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 45);
        doc.setFontSize(10);
        doc.text(':  ' + (this.Alldet.with_tax).toFixed(this.country_dec),  valueXPos, headerHeight + 45);
  
        if(this.userType == 'ADMIN'){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Auditor.Company_type')}`, lblXPos, headerHeight + 50);
          doc.setFontSize(10);
          doc.text(':  ' + this.Alldet.cmp_type,  valueXPos, headerHeight + 50);
        }
        }
        
        doc = this.excelService.addTableToPdf(doc, headerHeight + 55, lblXPos, heads, data, null);
    
       
        doc.save(`Credit Purchase.pdf`);

        this.exportLoader = false;

    });
    
  }

}
