import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Reppurchsum } from '../../../../../model/report/reppurchsum.model';
import * as XLSX from 'xlsx'; 
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-purchase-return-summary',
  templateUrl: './purchase-return-summary.component.html',
  styleUrls: ['./purchase-return-summary.component.css']
})
export class PurchaseReturnSummaryComponent implements OnInit {
  fileName= 'purchase_return_summary.xlsx';


  resultobj: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  period_type: any;
  date1: any;
  date2: any;
  inv_filter: any;
  inv_val: any;
  inv_val1: any;
  purch_price: any;
  price1: any;
  price2: any;
  purch_discount: any;
  discount1: any;
  discount2: any;
  purch_supp_id: any;
  payment_type: any;
  purch_type: any;
  pur_ref_filter:any;
  ret_ref_filter:any;
  branch_address:any;


  purchsumms:string[];
  curpage:number;
  lastpage:number;
  pgstart:boolean;
  pgend:boolean;
  slnum:number;
  supplierAll:string[];
  ModelData:boolean;
  // total_purchase:number;
  total_return:any;
  total_purchase_amount:any;
 
  pageLoadingImg: boolean;
  report_type:string;
  is_year_rep:any;
  filter_types:any;
  filter_refnum:any;
  filter_price:any;
  filter_discount:any;
  // filter_paytype:any;
  // filter_purchtypes:any;
  selectType:any;
  // selectRefNo:any;
  selectPrice:any;
  // selectDiscount:any;
 
 

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '2', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '2', name: 'Voided' },

  ];
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  up: any;
  Exppurchsumms: any;
  Exptotal_return: any;
  Expreport_type: any;
  ExpModelData: boolean;
  repDate: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  exportLoader: boolean;
  pdf: any;
  users:any;
  country_dec: string;
  cur_lang: string;
  pdfalias: any;
  pdfPur: any;
  constructor(private apiService: ApiService,private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }

  ngOnInit() {  
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.exportLoader = false;
    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    // this.filter_branch_id = 1;

    this.searchSupplier('');
    this.pageLoadingImg = true;

      let searchval = new FormData();
      this.apiService.purchaseReturnSummary(searchval,1).subscribe((res: Reppurchsum) => {
        this.pageLoadingImg = false;
      this.purchsumms = res['data']['data']; 
      this.pdf = res['data']['pdf'];
      this.pdfalias = res['data']['pdfalias'];

      this.curpage = res['data']['current_page'];  
      this.lastpage= res['data']['last_page']; 

      this.total_return = res['data']['report'];  
      this.total_purchase_amount= Number(res['data']['report']); 
     
      this.report_type= res['data']['report_type']; 
      if(this.report_type == 'year'){
        this.is_year_rep = true;
      }
      this.pgEnd(this.curpage,this.lastpage); 
      this.pgStart(this.curpage);
      this.slnum=0;

             this.ModelData = false; 
    });       
    this.searchUser('');
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
    this.branch_display_code= this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');

   this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
   
   
  }

  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.credit','Common.cash','Common.cash','Common.NonVoided','Production.Voided']).subscribe((res: string) => {    
     
      
      this.filterTypes = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
      this.filter_paytype = [
        { id: '1', name: res['HOME.credit'] },
        { id: '2', name: res['Common.cash'] },
    
      ];
    
      this.filter_purchtypes = [
        { id: '1', name: res['Common.NonVoided'] },
        { id: '2', name: res['Production.Voided'] },
    
      ];
    });

  }
  ngOnDestroy(){
    $('.close').click();
 
  }
  searchUser(search: string)
  {
   let searchval = new FormData();      
   searchval.append("usr_name", search);    
   this.apiService.getUserByName(searchval).subscribe((res:any) => {
        this.users = res.data;     
      });
  }


  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
       if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }






  pageNext(formdata: { value: any; }, pageno:any)
  {
  
    $('.resultdata').empty();
    this.pageLoadingImg = true;
  
  let searchval = new FormData();      
  searchval.append("pageno",pageno);

  if (formdata.value.period_type === 'c') {
    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;

  }

  
  this.apiService.purchaseReturnSummary(formdata.value,pageno).subscribe((res: Reppurchsum) => {
    this.pageLoadingImg = false;
    this.pdf = res['data']['pdf'];
    this.pdfalias = res['data']['pdfalias'];
  this.purchsumms = res['data']['data']; 
  this.curpage = res['data']['current_page'];  
  this.lastpage= res['data']['last_page']; 
  this.repDate = res['data'].Date;
  
  this.total_return = res['data']['report']; 
  this.report_type= res['data']['report_type']; 
  if(this.report_type == 'year'){
    this.is_year_rep = true;
  }
  
  
  this.pgEnd(this.curpage,this.lastpage); 
  this.pgStart(this.curpage);
  this.slnum=0;
  
  });
  }
    





  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res['data'];
    });

  }







  pgEnd(curr,end)
  {
  if(curr==end)
  this.pgend = true;
  else
  this.pgend = false;
  
  }
  
  pgStart(curr)
  {
  if(curr==1)
  this.pgstart = true;
  else
  this.pgstart = false;
  }


// Export 

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('export-group'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
     this.exportLoader = false;
    
  }




  export(formdata: { value: any; }){
    this.exportLoader = true;
     
    // debugger;
    formdata.value.export = 'export';
    this.apiService.purchaseReturnSummary(formdata.value, 1).subscribe((res: Reppurchsum) => {
      
    this.Exppurchsumms = res['data']['data']; 
    
    this.repDate = res['data'].Date;
    this.Exptotal_return = res['data']['report']; 
    this.Expreport_type= res['data']['report_type']; 
    this.ExpModelData = true; 

    
    
    this.pgEnd(this.curpage,this.lastpage); 
    this.pgStart(this.curpage);
    this.slnum=0;
    
    
   
    setTimeout(() => {
      this.exportexcel(); 
  }, 3000);
  
 

  });



  }

// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;
     
  // debugger;
  formdata.value.export = 'export';
  this.apiService.purchaseReturnSummary(formdata.value, 1).subscribe((res: Reppurchsum) => {
    
  this.Exppurchsumms = res['data']['data']; 
  
  
  this.Exptotal_return = res['data']['report']; 
  this.Expreport_type= res['data']['report_type']; 
  this.ExpModelData = true; 

  
  
  this.pgEnd(this.curpage,this.lastpage); 
  this.pgStart(this.curpage);
  this.slnum=0;
  
  
 
  const heads = [
    this.translate.instant('Table.sl') + '\n' + this.translate.instant('Common.no'),
    this.translate.instant('Purchase.return_ref_no'),
    this.translate.instant('Purchase.Purch_Ref_No'),
    this.translate.instant('Purchase.supplier'),
    this.translate.instant('Purchase.Pay_type'),
    this.translate.instant('Purchase.Return_Amount'),
    this.translate.instant('Purchase.Return_VAT_Amount')
  ];

    const lblXPos = 10;
    const headerHeight = 15;
    const valueXPos = 55;
    const data = [];

    this.Exppurchsumms.forEach(item=>{
      const arr=[
        item.date,
        '',
        '',
        '',
        '',
        (item.tot_amount).toFixed(this.country_dec),
        (item.tot_tax).toFixed(this.country_dec)
      ]
      data.push(arr)

      item.list.forEach((item1,i)=>{
        const arr=[]

        if(this.ModelData === true){
          arr.splice(0,0, i+1)
          arr.splice(1,0, item1.ret_rf_no)
          arr.splice(2,0, item1.purchret_refno)

          if(this.translate.currentLang == 'English'){
            arr.splice(3,0, item1.ledger.supp_name)
          } else if(this.translate.currentLang == 'Arabic'){
            arr.splice(3,0, item1.ledger.supp_alias)
          }

          if(item1.purchret_pay_type == 1){
            arr.splice(4,0, 'Credit')
          } else if(item1.purchret_pay_type == 2){
            arr.splice(4,0, 'Cash')
          } else {
            arr.splice(4,0, ' ')
          }

          arr.splice(5,0, (item1.return_amt).toFixed(this.country_dec))
          arr.splice(6,0, (item1.purchret_tax).toFixed(this.country_dec))

        }

        data.push(arr)
      })

    })

    let doc = this.excelService.getPdfObj();

    var addressLines = this.branch_address.split('\r\n');
    var addressText = addressLines.join(', ');

    doc.setFont('Amiri');
    doc.setFontSize(12);
    doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);

    doc.setFont('Amiri');
    doc.setFontSize(9);
    doc.text(addressText, 71, headerHeight + 2);

    if(this.repDate){
      doc.setFontSize(10);
      doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
      doc.setFontSize(10);
      doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
    }

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
    doc.setFontSize(10);
    doc.text(':  ' + `${this.translate.instant('Purchase.Purchase_Return_Summary')}`,  valueXPos, headerHeight + 15);



    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Purchase.Total_Return_s')}`, lblXPos, headerHeight + 20);
    doc.setFontSize(10);
    doc.text(':  ' + this.currencyPipe.transform(this.total_return.total_purchase, '', '', '1.1-1'),  valueXPos, headerHeight + 20);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Purchase.Return_Amount_as_Cash')}`, lblXPos, headerHeight + 25);
    doc.setFontSize(10);
    doc.text(':  ' + (this.total_return.total_cash).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Purchase.Return_Amount_as_Credit')}`, lblXPos, headerHeight + 30);
    doc.setFontSize(10);
    doc.text(':  ' + (this.total_return.total_credit).toFixed(this.country_dec),  valueXPos, headerHeight + 30);

    doc.setFontSize(10);
    doc.text(`${this.translate.instant('Purchase.Total_Return_Amount')}`, lblXPos, headerHeight + 35);
    doc.setFontSize(10);
    doc.text(':  ' + (this.total_return.total_return_amt).toFixed(this.country_dec),  valueXPos, headerHeight + 35);
    
    doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);

   
    doc.save(`Purchase Return Summary.pdf`);

    this.exportLoader = false;

});
  
}

}
