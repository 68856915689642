import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(value: any, decimalPlaces: number=2): any {
    if (!value) return value;

    // Multiplying and truncating to the given number of decimal places
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = (decimalPlaces>2)?Math.floor(value * factor) / factor:value;

    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }).format(truncatedValue);
    // return truncatedValue.toFixed(decimalPlaces);
    
  }


}
