import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx'; 
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-expenses-report',
  templateUrl: './expenses-report.component.html',
  styleUrls: ['./expenses-report.component.css']
})
export class ExpensesReportComponent implements OnInit {
  fileName= 'expenses.xlsx';


  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];
  period_type: string;
  pageLoadingImg: boolean;
  summary: any;
  curpage: any;
  lastpage: any;
  repDate: any;
  branch: any;
  pgend: boolean;
  pgstart: boolean;
  exportLoader: boolean;
  Expsummary: any;
  ExprepDate: any;
  Expbranch: any;
  Alldet: any;
  ExpAlldet: any;
  userType: any;
  branch_all: any;
  cmp_type: string;
  filter_cmptype = [
    { id: '1', name: 'Establishment' },
    { id: '2', name: 'Company' },

  ];
  filter_branch_id: any;
  cmpExist: boolean;
  country_dec: number=2;

  branch_display_name: any;
  branch_display_code: any;
  branch_address: any;
  ledAlias: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe,private datePipe: DatePipe) { }

  ngOnInit() {

    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.country_dec=this.coreService.setDecimalLength();
    this.cmp_type = '1';
    this.cmpExist = true;

    this.changeCmpType(1);
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();

    this.period_type = 't';
    let searchval = new FormData();
    searchval.append("period_type", 't');
    searchval.append("cmp_type",this.cmp_type);

    this.apiService.expensesReport(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.summary = res['data']['data']['data'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data'].Date;
      this.branch = res['data'].branch;
      this.Alldet = res['data'].Summary;

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);


    });


  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];
    });

  }

  pageNext(formdata: { value: any; }, pageno: any) {

    $('.resultdata').empty();
    this.pageLoadingImg = true;

    let searchval = new FormData();
    searchval.append("pageno", pageno);
    if (formdata.value.period_type === 'c') {
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    }
    formdata.value.export = '';

    this.apiService.expensesReport(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.pageLoadingImg = false;
      this.summary = res['data']['data']['data'];
      this.curpage = res['data']['data']['current_page'];
      this.lastpage = res['data']['data']['last_page'];
      this.repDate = res['data'].Date;
      this.branch = res['data'].branch;
      this.Alldet = res['data'].Summary;
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  changeCmpType(cmptype) {
    let searchval = new FormData();
    searchval.append("cmp_type", cmptype);
    this.filter_branch_id = [];

    this.apiService.getBranchbyType(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      this.filter_branch_id = res['selected'];
      this.checkBranch();
      
    });
  }
  
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      // if (this.userType == 'ADMIN') {
      //   // this.branch_all.push({ 'branch_id': '', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      // }
    });
  }

  checkBranch(){
    if(this.filter_branch_id.length > 0)
    {
      this.cmpExist = true;
    }else{
      this.cmpExist = false;
    }
  }


  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }


  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    export(expformdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      expformdata.value.export = 'export';
      this.apiService.expensesReport(expformdata.value, 1).subscribe((res: any) => {
        this.Expsummary = res['data']['data']['data'];
        
        this.ExprepDate = res['data'].Date;
        this.Expbranch = res['data'].branch;
        this.ExpAlldet = res['data'].Summary;
    
     
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
   

    });



    }

      // pdf download
  generatePdf(expformdata: { value: any; }) {

    this.exportLoader = true;
     
      // debugger;
      expformdata.value.export = 'export';
      this.apiService.expensesReport(expformdata.value, 1).subscribe((res: any) => {
        this.Expsummary = res['data']['data']['data'];
        
        this.ExprepDate = res['data'].Date;
        this.Expbranch = res['data'].branch;
        this.ExpAlldet = res['data'].Summary;
    
     
        const heads = [
          this.translate.instant('Table.date'),
          this.translate.instant('Auditor.Ledger Name'),
          this.translate.instant('Purchase.refno'),
          this.translate.instant('Auditor.Expenses Amount'),
          this.translate.instant('Purchase.vat'),
          this.translate.instant('Auditor.Total Expenses With vat')
        ];
      
          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 55;
          const data = [];
      
          this.Expsummary.forEach(item=>{
            if(this.translate.currentLang == "English"){this.ledAlias =  item.ledger_name}else{this.ledAlias =  item.ledger_alias}
            const arr=[
              item.date,
              this.ledAlias,
              item.branch_ref_no,
              (item.amount).toFixed(this.country_dec),
              '',
              ''
            ]
      
            data.push(arr)
          })
      
          let doc = this.excelService.getPdfObj();
      
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
      
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
      
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);
      
          if(this.ExprepDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.ExprepDate.date1 + '   to   ' + this.ExprepDate.date2  ,  valueXPos, headerHeight + 10);
          }
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Auditor.Expenses Report')} `,  valueXPos, headerHeight + 15);
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.branch_reg_no')}`, lblXPos, headerHeight + 20);
          if(this.branch.branch_tin){
            doc.setFontSize(10);
            doc.text(':  ' + this.branch.branch_tin,  valueXPos, headerHeight + 20);
          } else {
            doc.setFontSize(10);
            doc.text(':  ',  valueXPos, headerHeight + 20);
          }
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Auditor.Expenses Total')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + (this.Alldet.total_expenses).toFixed(this.country_dec),  valueXPos, headerHeight + 25);
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Branch.Activity')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + ' ',  valueXPos, headerHeight + 30);
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Branch.Mobile No')}`, lblXPos, headerHeight + 35);
          doc.setFontSize(10);
          doc.text(':  ' + this.branch.branch_mob,  valueXPos, headerHeight + 35);
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Branch.Charge Person')}`, lblXPos, headerHeight + 40);
          doc.setFontSize(10);
          doc.text(':  ' + ' ',  valueXPos, headerHeight + 40);

          if(this.userType == 'ADMIN'){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Auditor.Company_type')}`, lblXPos, headerHeight + 45);
            doc.setFontSize(10);
            doc.text(':  ' + this.Alldet.cmp_type,  valueXPos, headerHeight + 45);
          }
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 50, lblXPos, heads, data, null);
      
         
          doc.save(`Expenses Report.pdf`);
      
          this.exportLoader = false;
    
   

    });
    
  }

}
