
import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import { Sales } from '../../../../../model/report/sales.model';
import * as XLSX from 'xlsx'; 
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';
import { ExcelService } from 'src/app/service/excel.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-sales-void-summary',
  templateUrl: './sales-void-summary.component.html',
  styleUrls: ['./sales-void-summary.component.css']
})
export class SalesVoidSummaryComponent implements OnInit {
  fileName= 'sales_return_summary.xlsx';


  pageLoadingImg: boolean;
  value1:any;
  value2:any;
  inv_val1:any;
  inv_val2:any;
  sales_val1:any;
  sales_val2:any;
  disc_val1:any;
  agents:any;
 
  filter_types = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }

  ];

  filter_invno = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_salamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_saldic = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_servamt = [
    { id: 'between', name: 'Between' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
  ];

  filter_paytype = [
    { id: '1', name: 'Credit' },
    { id: '0', name: 'Cash' },

  ];

  filter_purchtypes = [
    { id: '1', name: 'NonVoided' },
    { id: '0', name: 'Voided' },

  ];

  filter_custtyp = [
    { id: '1', name: 'Registered' },
    { id: '0', name: 'Non Registered' },

  ];
  

  payType = ['Cash','Credit'];

  godowns:any;
  salesSum: any;
  totalCat: any;
  totalProducts: any;
  sup_id:any;
  cust_id:any;
  cust_type:any;
  pay_type:any;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;
  sales_disc_filter:any;
  sales_filter:any;
  inv_filter:any;
  period_type:any;
  gd_id:any;
  agent_id:any;
  company:any;
  supplier:any;
  users:any;
  customer:any;
  added_by:any;
  sales_flags:any;
  summary:any;
  repDate:any;
  totalData:number;
  date1:any;
  date2:any;
  time1:any;
  time2:any;
  vat_reg_no:any;
  ModelData: boolean;
  show: any;
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  Expshow: any;
  ExpsalesSum: any;
  Expsummary: any;
  branch_display_name: any;
  branch_name: any;
  branch_display_code: any;
  vans: any;
  exportLoader: boolean;
  pdf: any;
  country_dec: number=2;
  custid: any;
  ptype: any;
  cur_lang: string;
  pdfalias: any;
  body_pdf: any;
  branch_address: any;

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private currencyPipe: CurrencyPipe,private activatedRoute: ActivatedRoute,private excelService: ExcelService,private datePipe: DatePipe) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.exportLoader = false;
    
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    this.getGodownList();
    this.filter_branch_id = 1;
    this.period_type = 'ld';
    this.totalData = 0;
    this.pageLoadingImg = true;
    let searchval = new FormData();
    searchval.append("period_type", 'ld');
    this.activatedRoute.params.subscribe(params => {
      this.custid = params['custid'];
      this.ptype = params['ptype'];
      if (this.custid && this.ptype) {
             
        searchval.append("cust_id", this.custid);
        if(this.ptype=='c'){
          
          this.date1= this.apiService.formatDate(params['d1']);
          this.date2= this.apiService.formatDate(params['d2']);
          searchval.append("period_type", this.ptype);
          searchval.append("date1", this.date1);
          searchval.append("date2", this.date2);
        }else{
          searchval.append("period_type", this.ptype);
        }
        
      }
    });
    this.apiService.salesReturnSummary(searchval, 1).subscribe((res) => {
      this.pageLoadingImg = false;
      this.show = res['data'];
      // console.log("54sd");
      this.salesSum = res['data']['data']['data'];
      this.pdf = res['data']['pdf'];
      this.pdfalias = res['data']['pdfalias'];
      this.summary = res['data']['data'];
      this.repDate = res['data']['data'].Date;
        // console.log(this.summary);
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
    });
this.searchUser('');
this.ModelData = false;

this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name= this.coreService.getUserData('branch_name');
   this.branch_display_code= this.coreService.getUserData('branch_code');
   this.branch_address = this.coreService.getUserData('branch_address');

   this.country_dec=this.coreService.setDecimalLength();
   this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
   
   
  }


  langChange(){
    this.translate.get(['Common.today', 'Common.last_day','Common.last_week', 'Common.last_maonth','Common.last_yr', 'Common.cust_date','Common.all','HOME.credit','Common.cash','Common.NonVoided','Production.Voided','Common.registerd','Common.non_registerd']).subscribe((res: string) => {    
     
      
      this.filter_types = [
        
    { id: 't', name: res['Common.today'] },
    { id: 'ld', name: res['Common.last_day'] },
    { id: 'lw', name: res['Common.last_week'] },
    { id: 'lm', name: res['Common.last_maonth'] },
    { id: 'ly', name: res['Common.last_yr'] },
    { id: 'c', name: res['Common.cust_date'] },
    { id: '', name: res['Common.all'] }
        
      ];

      this.filter_paytype = [
        { id: '1', name:res['HOME.credit'] },
        { id: '0', name: res['Common.cash'] },
    
      ];
    
      this.filter_purchtypes = [
        { id: '1', name: res['Common.NonVoided'] },
        { id: '0', name: res['Production.Voided'] },
    
      ];
    
      this.filter_custtyp = [
        { id: '1', name: res['Common.registerd'] },
        { id: '0', name: res['Common.non_registerd'] },
    
      ];
    });

  }
  // generatePdf() {
  //      if(this.translate.currentLang == 'Arabic'){
  //     this.body_pdf=this.pdfalias;
  //      }else{
  //       this.body_pdf=this.pdf;
  //      }     
  //   var filename = 'Sales Return Summary';
  
  //   var docDefinition = {
  //     content: [
  //      {
  //         text: this.branch_display_name,
  //         bold: true,
  //         fontSize: 13,
  //         alignment: 'center',
  //         margin: [0, 0, 0, 20]
  //       },
  //       {
  //         text: filename,
  //         bold: true,
  //         fontSize: 16,
  //         alignment: 'center',
  //         margin: [0, 0, 0, 20]
  //       },
  //       {
  //         columns: [
  //           [
  //             {
  //               text:(this.repDate.date1&&this.repDate.date2)? 'Duration : '+this.repDate.date1+' - '+this.repDate.date2:'',
  //               fontSize: 11,
  //               margin: [0, 0, 0, 12]
  //             },
  //           {
  //             text: 'Total No. of Returns : '+this.summary.tot_result,

  //             fontSize: 11,
  //             margin: [0, 0, 0, 20]
  //           },
  //           {
  //             text: 'Total Returned  Amount: '+(this.summary.tot_inv_amt,'', '', this.country_dec),
  //             fontSize: 11,
  //             margin: [0, 0, 0, 20]
  //           },
  //           {
  //             text: 'Total Returned Vat Amount: '+(this.summary.tot_tax_amt,'', '', this.country_dec),
  //             fontSize: 11,
  //             margin: [0, 0, 0, 20]
  //           },
  //           {
  //             text: 'Total Returned Amount As Cash : '+(this.summary.tot_cash,'', '', this.country_dec),
  //             fontSize: 11,
  //             margin: [0, 0, 0, 20]
  //           },
  //           {
  //             text: 'Total Returned Amount As Credit : '+(this.summary.tot_credit,'', '', this.country_dec),
  //             fontSize: 11,
  //             margin: [0, 0, 0, 20]
  //           },
  //           {
  //             text: 'Discount Amount : '+(this.summary.tot_disc_amt,'', '', this.country_dec),
  //             fontSize: 11,
  //             margin: [0, 0, 0, 20]
  //           }
  //           ],
  //           [
  //             // Document definition for Profile pic
  //           ]
  //         ],
  //         columnStyles: { comment: { columnWidth: 'auto' } },
  //         styles: {
  //           name: {
  //             fontSize: 16,
  //             bold: true,
              
  //           },
  //           header: {
  //             fontSize: 18,
  //             bold: true,
  //             margin: [0, 20, 0, 10],
  //             decoration: 'underline'
  //           },
  //           jobTitle: {
  //             fontSize: 14,
  //             bold: true,
  //             italics: true
  //           },
  //           tableHeader: {
  //             bold: true,
  //           }
  //         },
  //       },
      
  //       {
  //         table: {
  //           // headers are automatically repeated if the table spans over multiple pages
  //           // you can declare how many rows should be treated as headers
        
  //           headerRows: 1,
  //           styles: {
  //             cellPadding: 0.5,
  //             fontSize: 12
  //         },
  //         //startY: 30, /* if start position is fixed from top */
  //         tableLineColor: [0, 0, 0], //choose RGB
  //         tableLineWidth: 0.5, //table border width
  //          //widths: [ '*', 'auto', '*', '100','*','*',,'*','*','*' ,'*' ],
  //           widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto','auto','auto'],
  //           body: this.body_pdf,
            
  //           bodyStyles: {
  //             margin: 40,
  //             fontSize: 10,
  //             lineWidth: 0.2,
  //             lineColor: [0, 0, 0]
  //         }, 
  //         theme: 'grid',
  //         columnStyles: {
  //           text: {
  //               cellWidth: 'auto'
  //           }
  //       }
  //         }
  //       }
  //     ]
  //   };
  //    pdfMake.createPdf(docDefinition).download(filename+".pdf");



  // }

  ngAfterContentInit(){
    
    this.searchVans('');
    this.searchComp('');
  }

  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];

      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
   


    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if(this.userType=='ADMIN')
      {
        this.branch_all.push({'branch_id':'All','branch_name':'All Branch' ,'branch_display_name':'All Branch'})
      }
    });
  }
  ngOnDestroy(){
    $('.close').click();
 
  }
 
  pageNext(formdata: { value: any; },newform: { value: any; }, pageno: any) {
    console.log('timeeeeeee');
    console.log(formdata.value);
    
    this.pageLoadingImg = true;
    formdata.value.datewise =  newform.value.datewise;
    if (formdata.value.period_type === 'c') {
      if(formdata.value.time1){
        this.time1 = new Date(formdata.value.time1);
      }
      if(formdata.value.time2){
        this.time2 = new Date(formdata.value.time2);
      }
      formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
      formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
      formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
      formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
    }
    this.apiService.salesReturnSummary(formdata.value, pageno).subscribe((res) => {
      this.pageLoadingImg = false;
      //console.log("54sd");
      //console.log(res['data']['pdf']);
      // if(res['data']['data']){
        this.show = res['data'];
      
      this.salesSum = res['data']['data']['data'];
      this.pdf = res['data']['pdf'];
      this.pdfalias = res['data']['pdfalias'];
      this.summary = res['data']['data'];
      this.repDate = res['data']['data'].Date;
      this.pageFrom = res['data']['from'];
      this.curpage = res['data']['current_page'];
      this.lastpage = res['data']['last_page'];
      this.totalData = res['data']['total'];
      // console.log(this.pdf);

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      // }
    });

  }


  
  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  
  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }


  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res['data'];
    });

  }

  searchUser(search: string)
  {
   let searchval = new FormData();      
      searchval.append("usr_name", search);    
      this.apiService.getUserByName(searchval).subscribe((res) => {
        this.users = res['data'];     
      });
  }

  searchAgent(search: string)
  {
   let searchval = new FormData();      
      searchval.append("usr_name", search);    
      this.apiService.getUserByName(searchval).subscribe((res) => {
        this.agents = res['data'];   
        // console.log(this.users);               
      });
  }

  searchGdwn(search: string) {
    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getGodownByName(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ "gd_id": 0, 'gd_name': 'All' });
    });

  }
  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }




  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('export-group'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, { raw: true });

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
       this.exportLoader = false;
			
    }


    exportEXCL(formdata: { value: any; }){
    this.exportLoader = true;
     
      // debugger;
      formdata.value.datewise = true;
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
        formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
      }
      this.apiService.salesReturnSummary(formdata.value,1).subscribe((res: Sales) => {
        this.pageLoadingImg = false;
        // if(res['data']['data']){
          this.Expshow = res['data'];
        
        this.ExpsalesSum = res['data']['data']['data'];
       
        this.Expsummary = res['data']['data'];
        this.repDate = res['data']['data'].Date;
      
        // console.log(this.totalData);
  
      
        // }
      
     
      setTimeout(() => {
        this.exportexcel(); 
    }, 3000);
    
   

    });



    }

    searchVans(search: string) {
      const searchval = new FormData();
      searchval.append('van_name', search);
      this.apiService.searchVans(searchval).subscribe((res) => {
        this.vans = res['data'];
        this.vans.push({ "van_id": 'All', 'van_name': 'All' });

      });
  
    }

// pdf download
generatePdf(formdata: { value: any; }) {

  this.exportLoader = true;
     
      // debugger;
      formdata.value.datewise = true;
      formdata.value.export = 'export';
      if (formdata.value.period_type === 'c') {
        formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
        formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
        formdata.value.time1 = (formdata.value.time1) ? this.apiService.formatTime(formdata.value.time1) : formdata.value.time1;
        formdata.value.time2 = (formdata.value.time2) ? this.apiService.formatTime(formdata.value.time2) : formdata.value.time2;
      }
      this.apiService.salesReturnSummary(formdata.value,1).subscribe((res: Sales) => {
        this.pageLoadingImg = false;
        // if(res['data']['data']){
          this.Expshow = res['data'];
        
        this.ExpsalesSum = res['data']['data']['data'];
       
        this.Expsummary = res['data']['data'];
        this.repDate = res['data']['data'].Date;
      
        const heads = [
          this.translate.instant('Table.sl_no'),
          this.translate.instant('Sales.Return_Ref_NO'),
          this.translate.instant('Sales.Return_Branch_Ref_No'),
          this.translate.instant('Sales.Sales_Invoice_No'),
          this.translate.instant('Sales.Name_Of_The_Customer'),
          this.translate.instant('Sales.Pay_Type'),
          this.translate.instant('Sales.Returned_Amount'),
          this.translate.instant('Sales.Vat_Amount'),
          this.translate.instant('Purchase.discount')
        ];

          const lblXPos = 10;
          const headerHeight = 15;
          const valueXPos = 75;
          const data = [];
      
          this.ExpsalesSum.forEach(item=>{
            const arr=[
              '',
              this.datePipe.transform(item.sales_date,"dd-MM-yyyy"),
              '',
              '',
              '',
              '',
              (item.tot_amount).toFixed(this.country_dec),
              '',
              (item.tot_disc_amt).toFixed(this.country_dec)
            ]

            data.push(arr)

            item.list.forEach((item1,i)=>{
              const arr1=[
                i+1,
                item1.salesret_no,
                item1.salesret_branch_ret_no,
                item1.salesret_sales_inv_no,
                this.payType[item1.sales_pay_type],
                (item1.sales_total).toFixed(this.country_dec),
                (item1.sales_tax).toFixed(this.country_dec),
                (item1.salesret_discount).toFixed(this.country_dec)
              ]

              if(this.translate.currentLang == 'English'){
                arr1.splice(4,0, item1.sales_cust_name)
              } else if(this.translate.currentLang != 'English'){
                arr1.splice(4,0, item1.selected_name)
              }

              data.push(arr1)
            })
          })
      
          let doc = this.excelService.getPdfObj();
      
          var addressLines = this.branch_address.split('\r\n');
          var addressText = addressLines.join(', ');
      
          doc.setFont('Amiri');
          doc.setFontSize(12);
          doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
      
          doc.setFont('Amiri');
          doc.setFontSize(9);
          doc.text(addressText, 71, headerHeight + 2);

          if(this.repDate){
            doc.setFontSize(10);
            doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
            doc.setFontSize(10);
            doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
          }
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
          doc.setFontSize(10);
          doc.text(':  ' + `${this.translate.instant('Sales.sales_return_summary')}`,  valueXPos, headerHeight + 15);
      
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_No_of_Returns')}`, lblXPos, headerHeight + 20);
          doc.setFontSize(10);
          doc.text(':  ' + this.summary.tot_result,  valueXPos, headerHeight + 20);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Returned_Amount')}`, lblXPos, headerHeight + 25);
          doc.setFontSize(10);
          doc.text(':  ' + (this.summary.tot_inv_amt).toFixed(this.country_dec),  valueXPos, headerHeight + 25);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Returned_Vat_Amount')}`, lblXPos, headerHeight + 30);
          doc.setFontSize(10);
          doc.text(':  ' + (this.summary.tot_tax_amt).toFixed(this.country_dec),  valueXPos, headerHeight + 30);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Returned_Amount_cash')}`, lblXPos, headerHeight + 35);
          doc.setFontSize(10);
          doc.text(':  ' + (this.summary.tot_cash).toFixed(this.country_dec),  valueXPos, headerHeight + 35);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Total_Returned_Amount_Credit')}`, lblXPos, headerHeight + 40);
          doc.setFontSize(10);
          doc.text(':  ' + (this.summary.tot_credit).toFixed(this.country_dec),  valueXPos, headerHeight + 40);

          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Sales.Discount_Amount')}`, lblXPos, headerHeight + 45);
          doc.setFontSize(10);
          doc.text(':  ' + (this.summary.tot_disc_amt).toFixed(this.country_dec),  valueXPos, headerHeight + 45);
          
          doc = this.excelService.addTableToPdf(doc, headerHeight + 50, lblXPos, heads, data, null);
      
         
          doc.save(`Sales and Return Summary.pdf`);
    
          this.exportLoader = false;

    });


  
}

}
