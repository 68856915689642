import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-rate-edit-log',
  templateUrl: './rate-edit-log.component.html',
  styleUrls: ['./rate-edit-log.component.css']
})
export class RateEditLogComponent implements OnInit {
  editLog: any;
  pageLoadingImg: boolean;
  pageFrom: any;
  curpage: any;
  lastpage: any;
  pgend: any;
  pgstart: any;

  constructor(private apiService: ApiService, private coreService: CoreService) { }

  ngOnInit() {

    let searchval = new FormData();
    this.pageLoadingImg = true;
    this.apiService.rateEditLog(searchval,1).subscribe((res) => {
      this.pageLoadingImg = false;
     
      this.editLog = res['data']['data']['data'];
      this.pageFrom =  res['data']['data']['from'];
      this.curpage =  res['data']['data']['current_page'];
      this.lastpage =  res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    })
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  pageNext(formdata: { value: any; },pageno: any){
    this.pageLoadingImg = true;
    let searchval = new FormData();
    this.apiService.rateEditLog(searchval,pageno).subscribe((res) => {
      this.pageLoadingImg = false;
     
      this.editLog = res['data']['data']['data'];
      this.pageFrom =  res['data']['data']['from'];
      this.curpage =  res['data']['data']['current_page'];
      this.lastpage =  res['data']['data']['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
  
    })

  }
}
