import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
// import * as XLSX from 'xlsx';

import { CoreService } from '../../../../../service/core.service';
import { ApiService } from '../../../../../service/api.service';
import * as XLSX from 'xlsx';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ExcelService } from '../../../../../service/excel.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-product-invoice',
  templateUrl: './product-invoice.component.html',
  styleUrls: ['./product-invoice.component.css']
})
export class ProductInvoiceComponent implements OnInit {
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  fileName = 'purchased_products_summary.xlsx';
  exportLoader: boolean;
  ExppurchInvSum: any[];
  Expreport_type: any;
  ExptotalQty: any;
  ExppurchaseAmount: any;
  ExptotalProducts: any;
  ExptotalCats: any;
  branch_display_name: any;
  branch_display_code: any;
  branch_name: any;
  title = 'Excel';
  calc_mode: any;
  purchaseBlock: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  date1: any;
  date2: any;
  period_type: any;
  purch_filter: any;
  purch_val: any;
  purch_val2: any;
  purch_rate_filter: any;
  purch_rate: any;
  purch_rate2: any;
  purch_qty_filter: any;
  purch_qty: any;
  purch_qty2: any;
  sup_id: any;
  prd_id: any;
  cat_id: any;
  manfact_id: any;
  reportType: any;
  rep_type: any;

  purchInvSum: any;
  curpage: number;
  lastpage: number;
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  company: any;
  products: any;
  supplier: any;
  data: any;
  pageLoadingImg: boolean;
  repDate: any;

  totalQty: number;
  purchaseAmount: any;
  totalProducts: any;
  totalCats: any;
  purchInvitem: any; ExportTOExcel: any;

  branch_address:any;

  filterTypes = [
    { id: 't', name: 'Today' },
    { id: 'ld', name: 'Last Day' },
    { id: 'lw', name: 'Last week' },
    { id: 'lm', name: 'Last Month' },
    { id: 'ly', name: 'Last Year' },
    { id: 'c', name: 'Custom Date' },
    { id: '', name: 'All' }
  ];

  // filter  types
  sortType = [
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '=', name: '=' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
    { id: 'between', name: 'Between' },
  ];
  catagories: any;


  rept_type = [

    { id: 1, name: 'Transfer & Returns' },
    { id: 2, name: 'Transfer' },
    { id: 3, name: 'Returns' },
  ];
  godowns: any;
  branch_all: any;
  filter_branch_id: number;
  userType: any;
  up: any;
  country_dec: number=2;
  cur_lang: string;
  prd: any;


  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService,private excelService: ExcelService,private currencyPipe: CurrencyPipe) { }
  getAllBranch() {
    this.apiService.readAllBranch().subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }
  searchBranch(search: string) {
    let searchval = new FormData();
    searchval.append("branch_name", search);
    this.apiService.getBranchByName(searchval).subscribe((res) => {
      this.branch_all = res['data'];
      if (this.userType == 'ADMIN') {
        this.branch_all.push({ 'branch_id': 'All', 'branch_name': 'All Branch', 'branch_display_name': 'All Branch' })
      }
    });
  }
  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.country_dec=this.coreService.setDecimalLength();
    this.up = this.coreService.getUserPrivilage();
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    this.pageLoadingImg = true;
    this.userType = this.coreService.getUserData('user_type');
    this.getAllBranch();
    // this.filter_branch_id = 1;
    this.getGodownList();

    const searchval = new FormData();
    this.apiService.prodInvoiceRep(searchval, 1).subscribe((res: any) => {
      this.pageLoadingImg = false;
      this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.reportType = res.data.reportType;


      if (res.data.allTotal) {
        this.totalQty = res.data.allTotal.total_qty;
        this.purchaseAmount = res.data.allTotal.purchase_amount;
        this.totalProducts = res.data.allTotal.total_products;
        this.totalCats = res.data.allTotal.total_cats;
      } else {
        this.totalQty = 0;
        this.purchaseAmount = 0;
        this.totalProducts = 0;
        this.totalCats = 0;

      }


      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
    this.branch_display_name = this.coreService.getUserData('branch_disp_name');
    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_display_code = this.coreService.getUserData('branch_code');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
    })
    
    
  }
  langChange(){
    this.translate.get(['Common.today','Common.last_day','Common.last_week','Common.this_month','Common.last_yr','Common.cust_date','Common.all','Common.Transfer_Returns','Common.Transfer','Common.Returns']).subscribe((res: string) => {

     this.filterTypes = [
        { id: 't', name:res['Common.today'] },
        { id: 'ld', name:res['Common.last_day'] },
        { id: 'lw', name:res['Common.last_week'] },
        { id: 'lm', name:res['Common.this_month'] },
        { id: 'ly', name:res['Common.last_yr'] },
        { id: 'c', name:res['Common.cust_date'] },
        { id: '', name:res['Common.all'] }
      ];
     this.rept_type = [
        { id: 1, name:res['Common.Transfer_Returns'] },
        { id: 2, name:res['Common.Transfer'] },
        { id: 3, name:res['Common.Returns'] },

      ];
    });

  }

  ngOnDestroy() {
    $('.close').click();

  }


  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];
      this.godowns.push({ 'gd_id': 'All', 'gd_name': 'All'})

    });
  }

  // FILTERS


  searchCat(search: string) {
    const searchval = new FormData();
    searchval.append('cat_name', search);
    this.apiService.getCatbyName(searchval).subscribe((res) => {
      this.catagories = res['data'];
    });

  }

  searchComp(search: string) {
    const searchval = new FormData();
    searchval.append('manftr_comp_name', search);
    this.apiService.getManfbyName(searchval).subscribe((res) => {
      this.company = res['data'];
    });

  }

  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
     if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append("with-alias", "1");
    }
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplier = res['data'];
    });

  }

  // search product
  selectProd(name: string) {

    const searchval = new FormData();
    searchval.append('prod_name', name);
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.products = res.data;
    });
  }

  //form search


  // FILTERS - End





  pageNext(formdata: { value: any; }, pageno: any) {

    formdata.value.export = '';
    $('.resultdata').empty();
    this.pageLoadingImg = true;

    const searchval = new FormData();
    searchval.append('pageno', pageno);

    this.apiService.prodInvoiceRep(formdata.value, pageno).subscribe((res: any) => {
      this.pageLoadingImg = false;

      this.purchInvSum = res.data.data;
      this.curpage = res.data.current_page;
      this.lastpage = res.data.last_page;
      this.reportType = res.data.reportType;

      this.repDate = res.data.Date;

      if (res.data.allTotal) {
        this.totalQty = res.data.allTotal.total_qty;
        this.purchaseAmount = res.data.allTotal.purchase_amount;
        this.totalProducts = res.data.allTotal.total_products;
        this.totalCats = res.data.allTotal.total_cats;
      } else {
        this.totalQty = 0;
        this.purchaseAmount = 0;
        this.totalProducts = 0;
        this.totalCats = 0;

      }

      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;

    });
  }

  pgEnd(curr, end) {
    if (curr == end) {
      this.pgend = true;
    } else {
      this.pgend = false;
    }

  }

  pgStart(curr) {
    if (curr == 1) {
      this.pgstart = true;
    } else {
      this.pgstart = false;
    }
  }

  // ExportTOExcel() {  
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);  
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();  
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
  //   XLSX.writeFile(wb, 'ScoreSheet.xlsx');  
  // }  

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('export-group');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.exportLoader = false;

  }



  exportEXCL(formdata: { value: any; }) {


    this.exportLoader = true;

    formdata.value.export = 'export';

    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    this.ExppurchInvSum = [];

    this.apiService.prodInvoiceRep(formdata.value, 1).subscribe((res: any) => {

      // this.data = res;

      this.ExppurchInvSum = res.data.data;

      this.repDate = res.data.Date;
      this.Expreport_type = res.data.rep_type;

      if (res.data.allTotal) {
        this.ExptotalQty = res.data.allTotal.total_qty;
        this.ExppurchaseAmount = res.data.allTotal.purchase_amount;
        this.ExptotalProducts = res.data.allTotal.total_products;
        this.ExptotalCats = res.data.allTotal.total_cats;
      } else {
        this.ExptotalQty = 0;
        this.ExppurchaseAmount = 0;
        this.ExptotalProducts = 0;
        this.ExptotalCats = 0;

      }

      setTimeout(() => {
        this.exportexcel();
      }, 3000);



    });

  }

  // pdf download
  generatePdf(formdata: { value: any; }) {

    this.exportLoader = true;

    formdata.value.export = 'export';

    formdata.value.date1 = (formdata.value.date1) ? this.apiService.formatDate(formdata.value.date1) : formdata.value.date1;
    formdata.value.date2 = (formdata.value.date2) ? this.apiService.formatDate(formdata.value.date2) : formdata.value.date2;
    this.ExppurchInvSum = [];

    this.apiService.prodInvoiceRep(formdata.value, 1).subscribe((res: any) => {

      // this.data = res;

      this.ExppurchInvSum = res.data.data;

      this.repDate = res.data.Date;
      this.Expreport_type = res.data.rep_type;

      if (res.data.allTotal) {
        this.ExptotalQty = res.data.allTotal.total_qty;
        this.ExppurchaseAmount = res.data.allTotal.purchase_amount;
        this.ExptotalProducts = res.data.allTotal.total_products;
        this.ExptotalCats = res.data.allTotal.total_cats;
      } else {
        this.ExptotalQty = 0;
        this.ExppurchaseAmount = 0;
        this.ExptotalProducts = 0;
        this.ExptotalCats = 0;

      }

      const heads = [
        this.translate.instant('Table.itm_name'),
        this.translate.instant('Table.barcode'),
        this.translate.instant('Common.category'),
        this.translate.instant('Common.sub_category'),
        this.translate.instant('Sales.Avg_Rate'),
        this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.qty'),
        this.translate.instant('Common.Purch') + '\n' + this.translate.instant('Table.amnt')
      ];

        const lblXPos = 10;
        const headerHeight = 15;
        const valueXPos = 55;
        const data = [];
  
        this.ExppurchInvSum.forEach(item=>{
          item.products.forEach(item1=>{
            item1.item.forEach(item2=>{
              if(this.translate.currentLang == "Arabic"){
                this.prd = item2.prd_alias
              }else{ this.prd = item2.prd_name}
              data.push([
              this.prd,
              item2.prd_barcode,
              item2.cat_name,
              item2.subcat_name,
              (item2.purchase_avg_amount).toFixed(this.country_dec),
              this.currencyPipe.transform(item2.purchsub_qty, '', '', '1.1-1'),
              (item2.purchase_amount).toFixed(this.country_dec)
              ])
            })
          })
        })
    
        let doc = this.excelService.getPdfObj();
  
        var addressLines = this.branch_address.split('\r\n');
        var addressText = addressLines.join(', ');
  
        doc.setFont('Amiri');
        doc.setFontSize(12);
        doc.text(this.branch_display_name +' ' + '(' + this.branch_display_code + ')', 70, headerHeight - 2);
  
        doc.setFont('Amiri');
        doc.setFontSize(9);
        doc.text(addressText, 71, headerHeight + 2);
  
        if(this.repDate){
          doc.setFontSize(10);
          doc.text(`${this.translate.instant('Common.Duration')}`, lblXPos, headerHeight + 10);
          doc.setFontSize(10);
          doc.text(':  ' + this.repDate.date1 + '   to   ' + this.repDate.date2  ,  valueXPos, headerHeight + 10);
        }
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('HOME.REPORT')}`, lblXPos, headerHeight + 15);
        doc.setFontSize(10);
        doc.text(':  ' + `${this.translate.instant('Purchase.Purchased_Products_Summary_Product_Based')}`,  valueXPos, headerHeight + 15);
  
        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_category')}`, lblXPos, headerHeight + 20);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalCats,  valueXPos, headerHeight + 20);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Table.totl_item')}`, lblXPos, headerHeight + 25);
        doc.setFontSize(10);
        doc.text(':  ' + this.totalProducts,  valueXPos, headerHeight + 25);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Purchase.Total_Purchase_Qty')}`, lblXPos, headerHeight + 30);
        doc.setFontSize(10);
        doc.text(':  ' + this.currencyPipe.transform(this.totalQty, '', '', '1.1-1'),  valueXPos, headerHeight + 30);

        doc.setFontSize(10);
        doc.text(`${this.translate.instant('Production.total_purchase_amount')}`, lblXPos, headerHeight + 35);
        doc.setFontSize(10);
        doc.text(':  ' + (this.purchaseAmount).toFixed(this.country_dec),  valueXPos, headerHeight + 35);
        
        if(this.reportType){
          doc.setFontSize(10);
          doc.text('Report Type', lblXPos, headerHeight + 35);
          doc.setFontSize(10);
          doc.text(':  ' + this.reportType,  valueXPos, headerHeight + 35);
        }

        doc = this.excelService.addTableToPdf(doc, headerHeight + 40, lblXPos, heads, data, null);
    
       
        doc.save(`Purchased Product Summary (Product Based).pdf`);

        this.exportLoader = false;

    });
    
  }


}
